import { useSelector } from 'react-redux';
import { userState } from '../../../shared/store/slices/userSlice';
import '../../plan-checkout/PlanCheckout.scss';

export default function CheckoutNavigation({ checkoutSection, setCheckoutSection }) {
  const userSel = useSelector(userState);

  const isFinanceAdmin = (user) => user?.role?.name === 'FinanceAdmin'

  const handlePlanNavigation = () => {
    if(!isFinanceAdmin(userSel)) {
      setCheckoutSection('Plan Details')
    }
  }

  return <div className="checkout-steps-header">
    <ul className="steps-list">
      <li>
        <a data-testid='nav-plan-section' className={checkoutSection === 'Plan Details' ? "active" : ""} onClick={handlePlanNavigation} >
          <span className="step-no">Step 1</span>
          <span className="step-name">Choose plan</span>
        </a>
      </li>
      <li>
        <a data-testid='nav-billing-section' className={checkoutSection === 'Billing Details' ? "active" : ""} onClick={() => setCheckoutSection('Billing Details')} >
          <span className="step-no">Step 2</span>
          <span className="step-name">Billing details</span>
        </a>
      </li>
      <li>
        <a data-testid='nav-payment-section' className={checkoutSection === 'Payment Details' ? "active" : ""} onClick={() => setCheckoutSection('Payment Details')} >
          <span className="step-no">Step 3</span>
          <span className="step-name">Payment</span>
        </a>
      </li>
    </ul>
  </div>
}