import { formatCurrency } from "./util"

export const getCreditsRange = ({ starting_credits, ending_credits }) => {
  if (ending_credits) {
    return `${starting_credits} - ${ending_credits}`
  }
  return `${starting_credits} onwards`
}

export const getPricesRange = ({ starting_price, ending_price }) => {
  if (ending_price) {
    return `${formatCurrency(starting_price)} - ${formatCurrency(ending_price)}`
  }
  return `${formatCurrency(starting_price)} onwards`
}

export const appReplacementText = (plan) => {
  if (plan.apps_replacement_count === 0) {
    return `No replacements`;
  }
  else if (plan.apps_replacement_count) {
    return `Up to ${plan.apps_replacement_count} app replacements per year`;
  }
  else {
    return `Unlimited replacements per year`;
  }
}

export const onboardingFeeText = ({ onboarding_fee }) => {
  if (!onboarding_fee) {
    return 'No onboarding fee'
  }
  else {
    return `${formatCurrency(onboarding_fee)} (one time fee charged at the beginning)`;
  }
}