import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUserData: (state, action) => {
      return { ...state, user: action.payload };
    },
    setSelectPlan: (state, action) => {
      return { ...state, selectedPlan: action.payload };
    },
    setCompany: (state, action) => {
      return { ...state, company: action.payload };
    },
    addPermissions: (state, action) => {
      return { ...state, permissions: action.payload };
    },
    addUserPermissions: (state, action) => {
      return { ...state, userPermissions: action.payload };
    },
    bmeetUrl: (state, action) => {
      return { ...state, bmeetMeetingUrl: action.payload };
    },
    setLeavingUrl: (state, action) => {
      return { ...state, leavingUrl: action.payload };
    },
    setUserTimezone: (state, action) => {
      return { ...state, userTimezone: action.payload };
    },
    resetUser: () => initialState,
  },
});

export const {
  addUserData,
  setSelectPlan,
  setCompany,
  resetUser,
  addPermissions,
  addUserPermissions,
  bmeetUrl,
  setLeavingUrl,
  setUserTimezone,
} = userSlice.actions;

export const userState = (state) => state?.userData?.user;
export const companyState = (state) => state?.userData?.company;
export const permissionState = (state) => state?.userData?.permissions;
export const userPermissionState = (state) => state?.userData?.userPermissions;
export const bmeetingUrlState = (state) => state?.userData?.bmeetMeetingUrl;
export const leavingUrlState = (state) => state?.userData?.leavingUrl;
export const userDataState = (state) => state?.userData;
export const userTimezoneState = (state) => state?.userData?.userTimezone;

export default userSlice.reducer;
