import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

export const billingDetailSlice = createSlice({
  name: 'billingContactInfo',
  initialState,
  reducers: {
    setBillingContactDetails: (state, action) => {
      return { ...state, billingContact: action.payload };
    },
    setBillingAddress: (state, action) => {
      return { ...state, billingAddress: action.payload };
    },
    setTaxDetails: (state, action) => {
      return { ...state, taxDetails: action.payload };
    },
  },
});

export const { setBillingContactDetails, setBillingAddress, setTaxDetails } =
  billingDetailSlice.actions;

export const contactDetailState = (state) =>
  state?.billingContactInfo?.billingContact;
export const billingAddressState = (state) =>
  state?.billingContactInfo?.billingAddress;
export const taxDetailState = (state) => state?.billingContactInfo?.taxDetails;

export default billingDetailSlice.reducer;
