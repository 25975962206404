import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

export const spaceSlice = createSlice({
  name: 'spaces',
  initialState,
  reducers: {
    addSpaces: (state, action) => {
      return { ...state, spaces: action.payload };
    },
    currentSpace: (state, action) => {
      return { ...state, currentSpace: action.payload }
    },
    setBuildcardId: (state, action) => {
      return { ...state, buildcardId: action.payload }
    },
    setEntBuildcardId: (state, action) => {
      return { ...state, entBuildcardId: action.payload }
    },
    setSelectedBuildcard: (state, action) => {
      return { ...state, selectedBuildcard: action.payload }
    },
    updateSpaceList: (state, action) => {
      return { ...state, updateList: action.payload }
    },
    resetSpaces: () => initialState
  },
})

export const { addSpaces, currentSpace, setBuildcardId, setEntBuildcardId, setSelectedBuildcard, updateSpaceList, resetSpaces } = spaceSlice.actions;

export const spaceState = (state) => state?.spaces?.spaces;
export const currentSpaceState = (state) => state?.spaces?.currentSpace;
export const buildcardState = (state) => state?.spaces?.buildcardId;
export const entBuildcardState = (state) => state?.spaces?.entBuildcardId;
export const selectedBuildcardState = (state) => state?.spaces?.selectedBuildcard;
export const updateSpaceListState = (state) => state?.spaces?.updateList;

export default spaceSlice.reducer;
