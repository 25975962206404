import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import guessCallingCode from 'guess-calling-code';
import { startTransition, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import images from '../../assets/images';
import instance from '../../shared/interceptor';
import { addUserData, setCompany } from '../../shared/store/slices/userSlice';
import { COMPANY, CONFIRM, SIGNUP } from '../../shared/util/constant';
import {
  encryptPassword,
  getCookie,
  globalError,
  navigateAccordingToRoleAndPaymentStatus,
  setCompanyToStorage,
  setLoader,
  setUserToStorage,
  userSystemTimezone,
  validateCountryCode,
} from '../../shared/util/util';
import QueryParamsHOC from './QueryParamsHOC';

const Signup = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isConditionAccepted, setIsConditionAccepted] = useState(false);
  const [error, setError] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [signUpFormVal, setSignUpFormVal] = useState({
    fullName: '',
    phone: '',
    password: '',
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const userCookie = getCookie(process.env.REACT_APP_USER);
    const token = userCookie ? JSON.parse(userCookie)?.['authtoken'] : null;
    if (token) {
      navigate('/dashboard');
    } else {
      if (props?.params) {
        (async () => await confirmUser(props?.params))();
      }
    }
    setCountryCode(`+${guessCallingCode()}`);
  }, [props?.params]);

  let region = '';

  const handleClickShowPassword = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  const inputChange = (event) => {
    let key = event.target.name;
    setSignUpFormVal({ ...signUpFormVal, [key]: event.target.value });
  };

  const confirmUser = async (params) => {
    try {
      setLoader(dispatch, true);
      setUserEmail(params['email']);
      const payload = {
        user: {
          email: params['email'],
        },
        confirmation: params['confirmation'],
      };
      let res = await instance.post(CONFIRM, payload);
      console.log(res['user']);
      if (res['user']['login_token']) {
        // TODO: redirect to respective page
        setUserToStorage(dispatch, addUserData, res);

        if (res?.['user']?.['company']?.['id']) {
          let companyRes = await instance.get(
            `${COMPANY}/${res['user']['company']['id']}`
          );
          setCompanyToStorage(dispatch, setCompany, companyRes);
          navigateAccordingToRoleAndPaymentStatus(
            startTransition,
            navigate,
            companyRes['company'],
            res['user']
          );
        }
      }
    } catch (e) {
      console.log(e, 'error in login confirmUser');
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  };

  const validatePayloadValues = () => {
    if (!signUpFormVal.fullName) {
      setError('Fullname is required.');
      return false;
    } else if (!countryCode) {
      setError('Country calling code is required.');
      return false;
    } else if (!signUpFormVal.phone) {
      setError('Phone number is required.');
      return false;
    } else if (!signUpFormVal.password) {
      setError('Password is required.');
      return false;
    } else if (signUpFormVal.password.length < 8) {
      setError('Password length should be of 8 characters.');
      return false;
    } else if (!validateCountryCode(countryCode, signUpFormVal.phone)) {
      setError('Invalid phone number with country calling code.');
      return false;
    } else if (!isConditionAccepted) {
      setError('Accept terms and condition.');
      return false;
    }
    region = validateCountryCode(countryCode, signUpFormVal.phone)['region'];
    setError('');
    return true;
  };

  const submitSignUpForm = async () => {
    if (validatePayloadValues()) {
      setError('');
      let { fullName, phone, password } = signUpFormVal;
      let [firstName, ...lastName] = fullName.split(' ').filter(Boolean);
      let payload = {
        user: {
          email: userEmail,
          password: encryptPassword(password),
          first_name: firstName,
          last_name: lastName.join(' '),
          country_code: countryCode,
          phone_number: phone,
          location: region,
          device_name: navigator?.userAgent,
          timezone: userSystemTimezone(),
        },
      };
      await postSignupFormVal(payload);
    }
  };

  const postSignupFormVal = async (payload) => {
    console.log(payload);
    try {
      setLoader(dispatch, true);
      let userRes: any = await instance.post(SIGNUP, payload);
      if (userRes.user.login_token) {
        setUserToStorage(dispatch, addUserData, userRes);
      }
      if (userRes?.user?.company?.id) {
        let companyRes = await instance.get(
          `${COMPANY}/${userRes.user.company.id}`
        );
        setCompanyToStorage(dispatch, setCompany, companyRes);
        navigateAccordingToRoleAndPaymentStatus(
          startTransition,
          navigate,
          companyRes['company'],
          userRes.user
        );
      }
    } catch (e: any) {
      console.log(e, 'error in signup postsignupFormVal');
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  };

  const submitForm = (e) => {
    if (e?.key === 'Enter') {
      e?.preventDefault();
      submitSignUpForm();
    }
  };

  //==============================================

  return (
    <section className="login-page is-signup">
      <div className="signup-slider">
        <em className="logo">
          <img src={images.builderLogo} alt="Builder Logo" />
        </em>
        <Swiper
          modules={[Navigation, Pagination]}
          slidesPerView={1}
          loop={true}
          spaceBetween={0}
          navigation
          pagination={{ clickable: true }}
        >
          <SwiperSlide>
            <div className="signup-slide">
              <div className="thumb-img">
                <img src={(images as any).signupSliderOne} alt="" />
              </div>
              <div className="slider-content">
                <p className="h2">
                  Builder Enterprise is a platform to propel your teams from
                  ideation to go-live.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="signup-slide">
              <div className="thumb-img">
                <img src={(images as any).signupSliderOne} alt="" />
              </div>
              <div className="slider-content">
                <p className="h2">
                  Slide 2 - Builder Enterprise is a platform to propel your
                  teams
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="signup-slide">
              <div className="thumb-img">
                <img src={(images as any).signupSliderOne} alt="" />
              </div>
              <div className="slider-content">
                <p className="h2">
                  Slide 3 - Builder Enterprise is a platform to propel your
                  teams
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="signup-form-wrapper">
        <div className="login-wrapper">
          <div className="login-outer">
            <div className="signup-title">
              <h1 className="lg bold">
                Join{' '}
                {props?.params?.company_name
                  ? `${props?.params?.company_name} on`
                  : ''}{' '}
                Builder Enterprise
              </h1>
              <p>
                {userEmail && <span>Your account email is {userEmail}.</span>}
                <span className="d-block">
                  Enter your name, phone and create a password to set up your
                  account.
                </span>
              </p>
            </div>
            <form onKeyDown={submitForm}>
              <div className="form-group">
                <FormControl variant="filled">
                  <InputLabel>Full name*</InputLabel>
                  {/* @ts-ignore */}
                  <FilledInput
                    inputProps={{ 'data-testid': 'fullName' }}
                    type="text"
                    name="fullName"
                    onChange={(e) => inputChange(e)}
                  />
                </FormControl>
              </div>

              <div className="form-group">
                <div className="phone-no-group">
                  <div className="country-code-field">
                    <label className="cc-label">Country code</label>
                    {/* @ts-ignore */}
                    <PhoneInput
                      country="in"
                      value={countryCode}
                      inputProps={{
                        'data-testid': 'phone',
                        name: 'phone',
                        required: true,
                      }}
                      onChange={(value, data, event, formattedValue) =>
                        setCountryCode(formattedValue)
                      }
                    />
                  </div>
                  <div className="phone-no-field">
                    <FormControl variant="filled">
                      <InputLabel>Phone number*</InputLabel>
                      {/* @ts-ignore */}
                      <FilledInput
                        inputProps={{ 'data-testid': 'number' }}
                        type="number"
                        name="phone"
                        onChange={(e) => inputChange(e)}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <FormControl variant="filled">
                  <InputLabel>Password*</InputLabel>
                  <FilledInput
                    inputProps={{ 'data-testid': 'password' }}
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    onChange={(e) => inputChange(e)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <em className="be-eye"></em>
                          ) : (
                            <em className="be-eye-off"></em>
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>

              <div className="form-group checkbox-form-group">
                <FormControlLabel
                  className="checkbox-control"
                  control={
                    <Checkbox
                      aria-label="checkbox"
                      checked={isConditionAccepted}
                      onClick={() =>
                        setIsConditionAccepted(!isConditionAccepted)
                      }
                      icon={<img src={images.checkboxUnchecked} alt="" />}
                      checkedIcon={<img src={images.checkboxChecked} alt="" />}
                    />
                  }
                  label={
                    <span>
                      I’ve read and agreed to the&nbsp;
                      <Link to="#" className="primary-link">
                        Privacy policy
                      </Link>{' '}
                      and{' '}
                      <Link to="#" className="primary-link">
                        Terms and conditions
                      </Link>
                    </span>
                  }
                />
              </div>
              <span data-testid="errorMsg" className="form-error">
                {error}
              </span>
              <div className="btn-block">
                <Button
                  data-testid="signup"
                  variant="contained"
                  className="green-btn"
                  onClick={submitSignUpForm as any}
                >
                  Create account
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QueryParamsHOC(Signup);
