import { configureStore } from '@reduxjs/toolkit';

import spaceSlice from './slices/companySlice';
import errorSlice from './slices/errorSlice';
import goalsSlice from './slices/goalSlice';
import loadingSlice from './slices/loadingSlice';
import spaceCreditSlice from './slices/spaceCreditSlice';
import successSlice from './slices/successSlice';
import userSlice from './slices/userSlice';
import billingDetailSlice from './slices/billingDetailSlice';

const store = configureStore({
  reducer: {
    userData: userSlice,
    error: errorSlice,
    success: successSlice,
    spaces: spaceSlice,
    loading: loadingSlice,
    spaceCredit: spaceCreditSlice,
    goals: goalsSlice,
    billingContactInfo: billingDetailSlice,
  },
});

export default store;
