import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      return { loading: action.payload }
    }
  },
})

export const { setLoading } = loadingSlice.actions;

export const loadingState = (state) => state?.loading;

export default loadingSlice.reducer;