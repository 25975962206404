import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import guessCallingCode from 'guess-calling-code';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import images from '../../assets/images';
import { companyState } from '../../shared/store/slices/userSlice';
import { VAT_AMOUNT, countryList } from '../../shared/util/constant';
import CheckoutSteps from './CheckoutSteps';
import './PlanCheckout.scss';
import { formatCurrency } from '../../shared/util/util';

export default function PlanBillingDetails({
  selectedPlan,
  setCheckoutSection,
}) {
  const navigate = useNavigate();
  const compSel = useSelector(companyState);

  const [EditMode, setEditMode] = useState(true);
  const [EditContactInfo, setEditContactInfo] = useState(false);
  const [EditBillingInfo, setEditBillingInfo] = useState(false);
  const [countryCode, setCountryCode] = useState('');

  useEffect(() => {
    setCountryCode(`+${guessCallingCode()}`);
  }, []);

  const toggleEditMode = () => {
    setEditMode((EditMode) => !EditMode);
  };
  const EditContactInfoDialogOpen = () => {
    setEditContactInfo(true);
  };
  const EditContactInfoDialogClose = () => {
    setEditContactInfo(false);
  };

  const EditBillingInfoDialogOpen = () => {
    setEditBillingInfo(true);
  };
  const EditBillingInfoDialogClose = () => {
    setEditBillingInfo(false);
  };

  const handleModalClose = (e, reason) => {
    if (reason && reason == 'backdropClick') return;
    EditContactInfoDialogClose();
    EditBillingInfoDialogClose();
  };

  //============================================

  return (
    <>
      <section
        className="plan-checkout-wrapper"
        aria-label="plan-billing-details"
      >
        <div className="checkout-content">
          <div className="page-container">
            <h2
              className="checkout-page-title h1 lg"
              onClick={toggleEditMode}
              aria-label="toggle-edit-btn"
            >
              Billing details
            </h2>

            {/* Billing details edit mode */}
            {EditMode ? (
              <div className="payment-container">
                <div className="billing-form-wrapper">
                  <div className="internal-section-title">
                    <h4>Contact information</h4>
                    <p>
                      We’ll save this as your company’s contact information
                      (within your company details).
                    </p>
                  </div>

                  <div className="form-fields-wrapper">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <FormControl variant="filled">
                            <InputLabel>Company name</InputLabel>
                            {/* @ts-ignore */}
                            <FilledInput type="text" variant="filled" />
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <FormControl variant="filled">
                            <InputLabel>First name</InputLabel>
                            {/* @ts-ignore */}
                            <FilledInput type="text" variant="filled" />
                          </FormControl>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <FormControl variant="filled">
                            <InputLabel>Last name</InputLabel>
                            {/* @ts-ignore */}
                            <FilledInput type="text" variant="filled" />
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <div className="phone-no-group">
                            <div className="country-code-field">
                              <label className="cc-label">Country code</label>
                              {/* @ts-ignore */}
                              <PhoneInput international country="in" />
                            </div>
                            <div className="phone-no-field">
                              <FormControl variant="filled">
                                <InputLabel>Phone number</InputLabel>
                                {/* @ts-ignore */}
                                <FilledInput type="number" variant="filled" />
                              </FormControl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <FormControl variant="filled">
                            <InputLabel>Email</InputLabel>
                            {/* @ts-ignore */}
                            <FilledInput type="text" variant="filled" />
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="billing-form-wrapper">
                  <div className="internal-section-title">
                    <h4>Address</h4>
                    <p>Your company’s billing address.</p>
                  </div>
                  <div className="form-fields-wrapper">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <FormControl variant="filled">
                            <InputLabel>Company name</InputLabel>
                            {/* @ts-ignore */}
                            <FilledInput type="text" variant="filled" />
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <Autocomplete
                            autoHighlight
                            options={countryList}
                            renderInput={(params) => (
                              <TextField {...params} label="Country" />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-9">
                        <div className="form-group">
                          <FormControl variant="filled">
                            <InputLabel>Street</InputLabel>
                            {/* @ts-ignore */}
                            <FilledInput type="text" variant="filled" />
                          </FormControl>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <FormControl variant="filled">
                            <InputLabel>Number</InputLabel>
                            {/* @ts-ignore */}
                            <FilledInput type="number" variant="filled" />
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <FormControl variant="filled">
                            <InputLabel>Office (optional)</InputLabel>
                            {/* @ts-ignore */}
                            <FilledInput type="text" variant="filled" />
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-9">
                        <div className="form-group">
                          <FormControl variant="filled">
                            <InputLabel>City</InputLabel>
                            {/* @ts-ignore */}
                            <FilledInput type="text" variant="filled" />
                          </FormControl>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <FormControl variant="filled">
                            <InputLabel>Zip code</InputLabel>
                            {/* @ts-ignore */}
                            <FilledInput type="text" variant="filled" />
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            {/* Billing details view mode */}
            {!EditMode ? (
              <>
                <div className="billing-view-wrapper">
                  <div className="view-flexbox">
                    <div className="left-info">
                      <h3>Contact information</h3>
                      <ul className="info-list">
                        <li>
                          <strong>HeyHey Company</strong>
                        </li>
                        <li>Jane Zimmermann</li>
                        <li>+44 243 43234</li>
                        <li>billing@heyhey.com</li>
                      </ul>
                    </div>
                    <div className="right-icon">
                      <img src={images.contactIcon} alt="" />
                    </div>
                  </div>
                  <div className="view-btn">
                    <Button
                      variant="contained"
                      className="sm-btn purple-btn"
                      onClick={EditContactInfoDialogOpen}
                      aria-label="edit-contact-btn"
                    >
                      Edit
                    </Button>
                  </div>
                </div>

                <div className="billing-view-wrapper">
                  <div className="view-flexbox">
                    <div className="left-info">
                      <h3>Address</h3>
                      <ul className="info-list">
                        <li>
                          <strong>HeyHey Company</strong>
                        </li>
                        <li>66 Grange Road</li>
                        <li>W19 6WN London</li>
                        <li>United Kingdom</li>
                      </ul>
                    </div>
                    <div className="right-icon">
                      <img src={images.addressIcon} alt="" />
                    </div>
                  </div>
                  <div className="view-btn">
                    <Button
                      variant="contained"
                      className="sm-btn purple-btn"
                      onClick={EditBillingInfoDialogOpen}
                    >
                      Edit
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="checkout-summary">
          <div className="checkout-content-outer">
            <div className="checkout-plan-title">
              <h4>Billing summary</h4>
            </div>
            <div className="checkout-costing">
              <div className="cost-row">
                <span className="cost-label">Yearly fixed cost</span>
                <span className="cost">
                  {formatCurrency(selectedPlan?.starting_price)}
                </span>
              </div>
              <div className="cost-row">
                <span className="cost-label">VAT</span>
                <span className="cost">$15,000</span>
              </div>
              <div className="cost-row total-row">
                <span className="cost-label">Total</span>
                <span className="cost">
                  {formatCurrency(
                    parseInt(selectedPlan?.starting_price) + VAT_AMOUNT
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="checkout-buttons">
            <Button
              className="green-btn"
              onClick={() => setCheckoutSection('Payment Details')}
              variant="contained"
            >
              Continue
            </Button>
          </div>
        </div>
      </section>

      {/* Edit contact information dialog starts */}
      <Dialog open={EditContactInfo} onClose={handleModalClose}>
        <DialogContent>
          {/* <span className="modal-close be-close" onClick={EditContactInfoDialogClose}></span> */}
          <div className="modal-body">
            <div className="modal-title">
              <h2>Edit contact information</h2>
            </div>
            <div className="modal-form">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <FormControl variant="filled">
                      <InputLabel>Company name</InputLabel>
                      {/* @ts-ignore */}
                      <FilledInput type="text" value="HeyHey Company" />
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <FormControl variant="filled">
                      <InputLabel>First name</InputLabel>
                      {/* @ts-ignore */}
                      <FilledInput type="text" variant="filled" value="Jane" />
                    </FormControl>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <FormControl variant="filled">
                      <InputLabel>Last name</InputLabel>
                      {/* @ts-ignore */}
                      <FilledInput type="text" value="Zimmermann" />
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <div className="phone-no-group">
                      <div className="country-code-field">
                        <label className="cc-label">Country code</label>
                        {/* @ts-ignore */}
                        <PhoneInput country="in" value={countryCode} />
                      </div>
                      <div className="phone-no-field">
                        <FormControl variant="filled">
                          <InputLabel>Phone number</InputLabel>
                          {/* @ts-ignore */}
                          <FilledInput type="number" variant="filled" />
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <FormControl variant="filled">
                      <InputLabel>Email</InputLabel>
                      {/* @ts-ignore */}
                      <FilledInput type="text" value="billing@heyhey.com" />
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="fixed-footer-wrap">
          <div className="fixed-full-footer">
            <Button
              variant="contained"
              className="sm-btn grey-btn"
              onClick={EditContactInfoDialogClose}
            >
              Cancel
            </Button>
            <Button variant="contained" className="sm-btn green-btn">
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {/* Edit contact information dialog ends */}

      {/* Edit billing info dialog starts */}
      <Dialog open={EditBillingInfo} onClose={handleModalClose}>
        <DialogContent>
          {/* <span className="modal-close be-close" onClick={EditBillingInfoDialogClose}></span> */}
          <div className="modal-body">
            <div className="modal-title">
              <h2>Edit billing information</h2>
            </div>
            <div className="modal-form">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <FormControl variant="filled">
                      <InputLabel>Company name</InputLabel>
                      {/* @ts-ignore */}
                      <FilledInput type="text" value="HeyHey Company" />
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <Autocomplete
                      autoHighlight
                      options={countryList}
                      renderInput={(params) => (
                        <TextField {...params} label="Country" />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-8">
                  <div className="form-group">
                    <FormControl variant="filled">
                      <InputLabel>Street</InputLabel>
                      {/* @ts-ignore */}
                      <FilledInput type="text" value="Grange Road" />
                    </FormControl>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <FormControl variant="filled">
                      <InputLabel>Number</InputLabel>
                      {/* @ts-ignore */}
                      <FilledInput type="number" variant="filled" value="66" />
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <FormControl variant="filled">
                      <InputLabel>Office (optional)</InputLabel>
                      {/* @ts-ignore */}
                      <FilledInput type="text" variant="filled" />
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-8">
                  <div className="form-group">
                    <FormControl variant="filled">
                      <InputLabel>City</InputLabel>
                      {/* @ts-ignore */}
                      <FilledInput type="text" value="London" />
                    </FormControl>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <FormControl variant="filled">
                      <InputLabel>Zip code</InputLabel>
                      {/* @ts-ignore */}
                      <FilledInput type="text" value="W19 6WN" />
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-btn-block"></div>
        </DialogContent>
        <DialogActions className="fixed-footer-wrap">
          <div className="fixed-full-footer">
            <Button
              variant="contained"
              className="grey-btn sm-btn"
              onClick={EditBillingInfoDialogClose}
            >
              Cancel
            </Button>
            <Button variant="contained" className="sm-btn green-btn">
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {/* Edit billing info dialog ends */}
    </>
  );
}
