export default function(Highcharts) {
  const H = Highcharts;
  H.wrap(H.PlotLineOrBand.prototype, 'render', function(proceed) {
    // proceed.apply(this, Array.prototype.slice.call(arguments, 1));

    let chart = this.axis.chart,
      options = this.options,
			marker = options.marker;

    if (marker) {
      let color = options.color,
        radius = 1,
        xAxis = chart.xAxis[0],
        x = chart.xAxis[0].toPixels(options.value),
        y = chart.plotTop;

        // for date indicator line
      chart.renderer.path(['M', x, y, 'L', x, 170]).attr({
        'stroke-width': xAxis?.options?.currentDateIndicator?.width ?? 2.5,
        stroke: color,
      }).add();

      // for top circle
      chart.renderer.symbol(marker, x - 5.5, y - 8, 12, 12).attr({
        fill: color
      }).add();
    }
  });
}
