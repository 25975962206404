import { useCallback, useContext, useEffect, useState } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

function useConfirmExit(confirmExit, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }

    const push = navigator.push;

    navigator.push = (...args) => {
      const result = confirmExit();
      if (result !== false) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
}

export function usePrompt(message, when = true, dependency = null) {
  const [showDialog, setShowDialog] = useState(dependency);
  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return message;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when, showDialog, dependency]);

  const confirmExit = useCallback(() => {
    setShowDialog(prev => true);
    return dependency;
  }, [message]);
  useConfirmExit(confirmExit, when);
  return [showDialog, setShowDialog];
}
