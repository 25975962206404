import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import images from '../../assets/images';
import UnauthorizedAccess from '../../components/UnauthorizedAccess';
import PurchaseOrderSentDialog from '../../components/dialog/purchase-dialog/PurchaseOrderSentDialog';
import SharePurchaseOrderDialog from '../../components/dialog/purchase-dialog/SharePurchaseOrderDialog';
import instance from '../../shared/interceptor';
import { userDataState } from '../../shared/store/slices/userSlice';
import { COMPANY, ON_BOARDING_WAITING_PAYMENT, PENDING_PURCHASE_ORDER, PURCHASE_ORDER, SHOW_PLANS, VAT_AMOUNT } from '../../shared/util/constant';
import { formatCurrency, globalError, resendPurchaseOrderEmail, setLoader, snakeCaseToSentence, validateEmail } from '../../shared/util/util';
import './PlanCheckout.scss';
import { appReplacementText, getCreditsRange, getPricesRange, onboardingFeeText } from '../../shared/util/plansUtil';

export default function CheckoutPlanSelection({ selectedPlan, setCheckoutSection }) {
  const [sharePurchaseOrder, setSharePurchaseOrder] = useState(false);
  // const [enterprisePlans, setEnterprisePlans] = useState([]);
  const [sharePurchaseSent, setSharePurchaseSent] = useState(false);
  const [financeEmail, setFinanceEmail] = useState('');
  const [resendOrder, setResendOrder] = useState(false);
  const [error, setError] = useState('');
  const [purchaseOrder, setPurchaseOrder] = useState(null);

  const userData = useSelector(userDataState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const emailChangeHandler = (e) => {
    e.preventDefault();
    setFinanceEmail(e.target.value);
  }

  const createPurchaseOrder = (key, company_plan_id) => {
    let value = key === 'email' ? financeEmail : userData?.user?.login_token;
    let payload = {
      purchase_order: {
        [key]: value,
        company_plan_id
      }
    }
    let companyId = userData?.user?.company?.id;
    return instance.post(`${COMPANY}/${companyId}/${PURCHASE_ORDER}`, payload);
  }

  const pendingPurchaseOrder = async () => {
    try {
      setLoader(dispatch, true);
      let companyId = userData.company?.id;
      let purchaseOrder = await instance.get(`${COMPANY}/${companyId}/${PENDING_PURCHASE_ORDER}`);
      return purchaseOrder;
    } catch (e: any) {
      console.log(e, 'error in waiting on payment pending purchase order');
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const changeStates = () => {
    setFinanceEmail('');
    setResendOrder(true);
    setSharePurchaseSent(false);
    setSharePurchaseOrder(true);
  }

  const resendPurchaseOrder = async () => {
    let purchaseOrder = await pendingPurchaseOrder();
    if (purchaseOrder) {
      setPurchaseOrder(purchaseOrder['purchase_order']);
      changeStates();
    }
  }

  const storeSelectedPlan = () => {
    let payload = {
      plan_credit_subscription: {
        plan_id: selectedPlan?.id
      }
    }
    let companyId = userData?.user?.company?.id;
    return instance.post(`${COMPANY}/${companyId}/plan_credit_subscriptions`, payload);
  }

  const resendEmail = async () => {
    if (validateEmail(financeEmail)) {
      try {
        setError('');
        await resendPurchaseOrderEmail(financeEmail, purchaseOrder, userData?.user?.company?.id);
      } catch (e: any) {
        globalError(dispatch, e);
      }
    } else {
      setError('Provide a valid email address');
    }
  }

  const sendEmail = async () => {
    if (validateEmail(financeEmail)) {
      try {
        setError('');
        if (resendOrder) {
          await resendEmail();
        } else {
          const planCreditSub = await storeSelectedPlan();
          await createPurchaseOrder('email', planCreditSub?.['plan_credit_subscription']?.plan?.id)
        }
        setSharePurchaseOrder(false);
        setSharePurchaseSent(true);
      } catch (e: any) {
        globalError(dispatch, e);
      }
    } else {
      setError('Provide a valid email address');
    }
  }

  const navigateToOverview = () => {
    navigate(ON_BOARDING_WAITING_PAYMENT);
  }

  const handleBackClick = () => {
    navigate(SHOW_PLANS)
  }

  const navigateToBilling = async () => {
    try {
      setError('');
      const planCreditSub = await storeSelectedPlan();
      await createPurchaseOrder('token',  planCreditSub?.['plan_credit_subscription']?.plan?.id);
      setCheckoutSection('Billing Details');
    } catch (e: any) {
      console.log(e, 'error here=====================')
      globalError(dispatch, e);
    }
  }

  const notAuthorized = () => {
    return <UnauthorizedAccess />
  }

  //====================================================
  return (
    <>
      {userData?.user?.role?.name !== 'Finance Admin' ? <section className="plan-checkout-wrapper" aria-label='checkout-plan-details'>
        {selectedPlan && <>
          <div className="checkout-content">
            <div className="page-container">
              <div className="plan-checkout-block">
                <div className="left-plan">
                  <div className="plan-name-wrapper">
                    <div className="plan-icon">
                      <img src={selectedPlan.image_url} alt="" />
                    </div>
                    <h3>{selectedPlan.name}</h3>
                    <h4>{getPricesRange(selectedPlan)}</h4>
                    <p>Incremental spends in tranches of <strong>{formatCurrency(selectedPlan.incremental_price)}</strong></p>
                  </div>
                  <div className="plan-inner-content">
                    <h2>{getCreditsRange(selectedPlan)}</h2>
                    <h4>credits</h4>
                    <ul>
                      <li>Up to {selectedPlan.apps_count} apps built</li>
                      <li>{appReplacementText(selectedPlan)}</li>
                      <li>Service type: {snakeCaseToSentence(selectedPlan?.service_type)}</li>
                    </ul>
                    <Button className="plan-btn green-btn" onClick={handleBackClick}><span className="be-right-arrow-thin"></span> Change Plan</Button>
                  </div>
                </div>
                <div className="plan-checkout-table">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th scope="row">Initial spend commitment <em className="be-info"></em></th>
                        <td>{getPricesRange(selectedPlan)}</td>
                      </tr>
                      <tr>
                        <th scope="row"># of Credits <em className="be-info"></em></th>
                        <td>{getCreditsRange(selectedPlan)}</td>
                      </tr>
                      <tr>
                        <th scope="row">Onboarding fee <em className="be-info"></em></th>
                        <td>{onboardingFeeText(selectedPlan)}</td>
                      </tr>

                      <tr>
                        <th scope="row">Typical number of apps <em className="be-info"></em></th>
                        <td>{`${selectedPlan.apps_count} apps of high complexity at minimum spend commitment`}</td>
                      </tr>

                      <tr>
                        <th scope="row">App replacement <em className="be-info"></em></th>
                        <td>
                          {selectedPlan.apps_replacement_count === 0 ? 'No replacements' : (
                            selectedPlan.apps_replacement_count ? `${selectedPlan.apps_replacement_count} replacements per app per year` :
                              'Unlimited replacements'
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Core Ent. Surface platform <em className="be-info"></em></th>
                        <td><span className="check-icon"><img src={images.iconCheckCircleGreen} alt="" /></span></td>
                      </tr>
                      <tr>
                        <th scope="row">App development engine <em className="be-info"></em></th>
                        <td><span className="check-icon"><img src={images.iconCheckCircleGreen} alt="" /></span></td>
                      </tr>
                      <tr>
                        <th scope="row">Choice of Builder.ai squad <em className="be-info"></em></th>
                        <td><span className="check-icon"> <img src={images.iconCheckCircleGreen} alt="" /></span>
                          <p>{snakeCaseToSentence(selectedPlan.squad_type)}</p>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Cloud service <em className="be-info"></em></th>
                        <td><span className="check-icon"><img src={images.iconCheckCircleGreen} alt="" /></span>
                          <p>Cloud for PoC, UAT and up to $1K per month of production infrastructure included across Tiers</p>
                          <span className="check-icon yellow"> <img src={images.iconCheckCircleYellow} alt="" /></span>
                          <p>Additional cloud enhancements/ scale/ capacity offered as paid add-on <span>(Subsequent paid renewal required)</span></p>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Customer support <em className="be-info"></em></th>
                        <td>
                          <span className="check-icon"> <img src={images.iconCheckCircleGreen} alt="" /></span>
                          {selectedPlan.customer_support_type != 'enterprise_one_plus' && (
                            <>
                              <p>Standard support <a href={void (0)}>(Enterprise One)</a> offered for 12 months post release <span>(Subsequent paid renewal required)</span></p>
                              <span className="check-icon yellow"> <img src={images.iconCheckCircleYellow} alt="" /></span>
                            </>
                          )}
                          <p>Priority support <a href={void (0)}>(Enterprise One+)</a> offered for 12 months for all apps post release <span>(Subsequent paid renewal required) </span></p>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Accelerated dev. timelines <em className="be-info"></em></th>
                        <td><span className="check-icon"> <img src={images.iconCheckCircleGreen} alt="" /></span>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">3P service marketplace <em className="be-info"></em></th>
                        <td><span className="check-icon"><img src={images.iconCheckCircleGreen} alt="" /></span>
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>

          <div className="checkout-summary">
            <div className="checkout-content-outer">
              <div className="checkout-plan-title">
                <h4>Billing summary</h4>
              </div>
              <div className="checkout-costing">
                <div className="cost-row">
                  <span className="cost-label">Yearly fixed cost</span>
                  <span className="cost">{formatCurrency(selectedPlan?.starting_price)}</span>
                </div>
                <div className="cost-row">
                  <span className="cost-label">VAT</span>
                  <span className="cost">$15,000</span>
                </div>
                <div className="cost-row total-row">
                  <span className="cost-label">Total</span>
                  <span className="cost">{formatCurrency(parseInt(selectedPlan?.starting_price) + VAT_AMOUNT)}</span>
                </div>
              </div>
            </div>
            <div className="checkout-buttons">
              {!sharePurchaseOrder && <p aria-label="error" className="form-error">{error}</p>}
              <Button aria-label='purchase-btn' variant="contained" className='grey-btn' /*disabled={!selectedPlan?.id}*/ onClick={() => { setError(''); setSharePurchaseOrder(true) }}>
                {/* <em className="be-share btn-icon"></em> */}
                Share purchase order
              </Button>
              <Button aria-label="navigate" variant="contained" onClick={navigateToBilling} className='green-btn' /*disabled={!selectedPlan?.id}*/>Continue</Button>
            </div >
          </div >
        </>}
      </section > : notAuthorized()}

      {/* Share purchase order dialog starts */}
      <SharePurchaseOrderDialog {...{
        sharePurchaseOrder,
        setSharePurchaseOrder,
        resendOrder,
        setError,
        financeEmail,
        emailChangeHandler,
        error,
        purchaseOrder,
        sendEmail
      }}
      />
      {/* Share purchase order dialog ends */}

      {/* Purchase order sent dialog starts */}
      <PurchaseOrderSentDialog {...{
        sharePurchaseSent,
        setSharePurchaseSent,
        financeEmail,
        resendOrder,
        resendPurchaseOrder,
        navigateToOverview,
        setError
      }} />
      {/* Purchase order sent dialog ends */}
    </>
  )
}
