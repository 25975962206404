interface Props {
  style?: any;
  border?: boolean;
  borderLessClass?: string;
  maxHeight?: string;
  borderLessStyle?: any;
}

function LoaderComp(props: Props) {
  return !props?.border ? (
    <div
      className={`searching-loader ${props?.borderLessClass ? props?.borderLessClass : ''}`}
      style={{
        height: `calc(100vh - ${props?.maxHeight})`,
        ...props?.borderLessStyle,
      }}
    >
      <div className="dot-flashing"></div>
    </div>
  ) : (
    <div className="loading-container" style={props?.style}>
      <div className="dot-flashing"></div>
    </div>
  );
}

export default LoaderComp;
