import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CancelCreditDialog from "../../components/dialog/space-credit/CancelCreditDialog";
import CreditHistoryDialog from "../../components/dialog/space-credit/CreditHistoryDialog";
import RequestCreditDialog from "../../components/dialog/space-credit/RequestCreditDialog";
import { getSpaceCreditData } from "../../shared/services/common.service";
import { currentSpaceState } from "../../shared/store/slices/companySlice";
import { companyState } from "../../shared/store/slices/userSlice";

export default function SpaceCreditModals({ 
  openRequestCreditDialogue, setOpenRequestCreditDialogue,
  openCreditRequestHistoryDialogue, setOpenCreditRequestHistoryDialogue
}) {
  const dispatch = useDispatch();
  const currentSpaceSel = useSelector(currentSpaceState);
  const compSel = useSelector(companyState);

  const [openCancelCreditRequestDialogue, setOpenCancelCreditRequestDialogue] = useState(null);

  const handleClose = () => {
    setOpenCreditRequestHistoryDialogue(false);
  };

  const handleClickOpen = () => {
    setOpenCreditRequestHistoryDialogue(true);
  };

  const handleClickOpen2 = (data) => {
    setOpenCancelCreditRequestDialogue(data);
  };

  const closeCancelRequestDialogue = (render = false) => {
    setOpenCancelCreditRequestDialogue(null);
    if (render) {
      getSpaceCreditData(compSel, currentSpaceSel, dispatch);
      handleClickOpen();
    }
  }

  const closeRequestCreditDialogue = (render = false) => {
    setOpenRequestCreditDialogue(null);
    if (render) {
      getSpaceCreditData(compSel, currentSpaceSel, dispatch);
    }
  };

  return (
    <>
      {/* Credit history dialogue */}
      {openCreditRequestHistoryDialogue && <CreditHistoryDialog {...{ openCreditRequestHistoryDialogue, handleClose, handleClickOpen2 }} />}
      {/* Credit history dialogue end */}

      {/* Cancel credit request dialogue */}
      {openCancelCreditRequestDialogue && <CancelCreditDialog {...{ openCancelCreditRequestDialogue, handleClose, closeCancelRequestDialogue }} />}
      {/* Cancel credit request dialogue end */}

      {/* Request credit dialogue */}
      {openRequestCreditDialogue && <RequestCreditDialog {...{ openRequestCreditDialogue, closeRequestCreditDialogue, handleClickOpen }} />}
      {/* Request credit dialogue end */}
    </>
  )
}