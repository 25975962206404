import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";

const QueryParamsHOC = (Comp) => {
    const QueryParams = () => {
        const [searchParams] = useSearchParams();
        const [params, setParams] = useState(null);

        useEffect(() => {
            (async () => getQueryParams())();
          }, []);
        
          const getQueryParams = async () => {
            let paramsObj = {};
            for (let [key, val] of searchParams) {
                paramsObj[key] = val;
            }
            if (paramsObj && Object.keys(paramsObj).length) {
                setParams(paramsObj);
            }
        }
        return <Comp params={params} />
    }
    return QueryParams;
}

export default QueryParamsHOC;
