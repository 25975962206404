import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Tooltip } from '@mui/material';

import images from '../../assets/images';
import { copyTextToClipboard, globalError } from '../../shared/util/util';

export default function CopyButton({
  copyText,
  btnStyle = 'xs-btn is-icon-btn white-btn has-border',
}) {
  const dispatch = useDispatch();

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    try {
      await copyTextToClipboard(copyText);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    } catch (e) {
      globalError(dispatch, e);
    }
  };

  return (
    <div className="icon-btn-wrap copy-button">
      <Tooltip
        placement="top-end"
        arrow
        title={isCopied ? 'Copied' : 'Copy'}
        classes={{ popper: 'info-tooltip' }}
      >
        <Button
          variant="contained"
          className={btnStyle}
          onClick={handleCopyClick}
        >
          <em className="thumbnail">
            <img src={images.copyIcon} alt="" />
          </em>
        </Button>
      </Tooltip>
    </div>
  );
}
