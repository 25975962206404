import { useRef, useEffect } from 'react';

export default function useOutSideClick(callback) {
    const innerRef = useRef();
    const callbackRef = useRef();
  
    useEffect(() => {
      callbackRef.current = callback;
    });
  
    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);
  
      function handleClick(e) {
        if (
          innerRef?.current && 
          callbackRef?.current &&
          !(innerRef?.current as any)?.contains(e?.target)
        ) {
          (callbackRef as any)?.current(e);
        }
      }
    }, []);
    
    return innerRef;
}
