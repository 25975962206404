import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import images from '../../assets/images';
import instance from '../../shared/interceptor';
import { RESET_PASS, VERIFY_RESET_PASSWORD } from '../../shared/util/constant';
import { encryptPassword, getCookie, globalError, setLoader } from '../../shared/util/util';
import QueryParamsHOC from './QueryParamsHOC';
import { loadingState } from '../../shared/store/slices/loadingSlice';

const ResetPassword = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loaderSel = useSelector(loadingState);

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [showUI, setShowUI] = useState(null);
  const [userData, setUserData] = useState({email: '', token: ''});
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const userCookie = getCookie(process.env.REACT_APP_USER);
    const token =  userCookie ? JSON.parse(userCookie)?.['authtoken'] : null;
    if (token) {
      navigate('/dashboard');
    } else {
      if (props?.params) {
        const controller = new AbortController();
        const signal = controller.signal;

        confirmUser(props?.params, signal);

        return () => {
          controller.abort();
        }
      }
    }
  }, [props.params]);

  const confirmUser = async (params, signal?) => {
    try {
      setLoader(dispatch, true);
      setUserData({email: params['email'], token: params['reset_password_token']});
      let res = await instance.get(VERIFY_RESET_PASSWORD, { params: {reset_password_token: params['reset_password_token'], 'user[email]': params['email']}, signal});
      setUser(res['user']);
      setShowUI(true);
    } catch (e) {
      setShowUI(false);
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const handleClickShowPassword = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  }

  const sendNewPassword = async () => {
    if (password?.length < 8) {
      setError('Password length should be of 8 characters.');
      return;
    }
    if(user) {
      try {
        setError('');
        setLoader(dispatch, true);
        let url = `${RESET_PASS}`;
        let payload = {
          user: {
            email: userData?.email,
            password: encryptPassword(password)
          },
          reset_password_token: userData?.token
        }
        await instance.post(url, payload);
        setShowPasswordReset(true);
      } catch(e) {
        setShowPasswordReset(false);
        globalError(dispatch, e);
      } finally {
        setLoader(dispatch, false);
      }
    }
  }

  const submitForm = (e) => {
    if (e?.key === 'Enter') {
      e?.preventDefault();
      sendNewPassword();
    }
  }

  //=======================================================

  return (
    <section className="login-page">
      
      { showUI && <div className={"login-wrapper " + (showPasswordReset ? 'lg' : '')}>
        
        <div className="login-outer">
          <em className="logo secondary">
            <img src={images.builderEnterpriseLogo} alt="Builder Enterprise" />
          </em>
          {!showPasswordReset ?
            <form onKeyDown={submitForm}>
              <div className="signup-title secondary">
                <h1 className='m-0' aria-label="heading">Set a new password</h1>
                <p className='user-email'>{ userData?.email }</p>
              </div>

              <div className="form-group">
                <FormControl variant="filled">
                  <InputLabel>Password</InputLabel>
                  <FilledInput
                    inputProps={{'aria-label': 'password'}}
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          data-testid="pass-icon"
                          aria-label="toggle password visibility"
                          onClick={(e) => handleClickShowPassword(e)}
                          edge="end"
                        >
                          {showPassword ? <em className='be-eye'></em> : <em className='be-eye-off'></em>}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <span className="form-error">{ error }</span>
                </FormControl>
              </div>

              <div className="btn-block lg-space">
                <Button data-testid='signin' variant="contained" className='green-btn' disabled={!password?.length} onClick={sendNewPassword}>Change password</Button>
              </div>

              <div className="forgot-link secondary">
                <Link to='/signin' className="primary-link">Back to Sign in</Link >
              </div>
            </form>

            :

            <>
              <div className='success-generic-box'>
                <em className="icon"><img src={images.roundedTickGreen} alt="" /></em>
                <div className="success-title">
                  <h2 className="h1 lg">Your password has been reset.</h2>
                  <p>Sign in with your new password</p>
                </div>
                <Button data-testid="navigate-btn" variant="contained" onClick={() => navigate('/signin')}>Sign in</Button>
              </div>
            </>
          }


        </div>

      </div> }
      { !loaderSel?.loader && showUI === false  && <div>User Not confirmed</div>}
    </section >
  )
}

export default QueryParamsHOC(ResetPassword);
