import Button from '@mui/material/Button';
import { startTransition, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import images from '../../assets/images';
import { companyState, setCompany } from '../../shared/store/slices/userSlice';
import { signInAs } from './DemoAuthProcess';
import './DemoPurpose.scss';

function DemoPurposeSignin() {
  const compSel = useSelector(companyState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!compSel) {
      dispatch(setCompany(JSON.parse(localStorage?.getItem('company'))));
    }
  }, [])

  // ==========================================
  return (
    <section className="login-page" aria-label="demo-signin">
      <div className="login-wrapper">
        <div className="login-outer">          
          <div className="signup-title text-center">
            <em className="logo">
              <img src={images.builderEnterpriseLogo} alt="Builder Enterprise" />
            </em>
            <h1>Sign in to demo account</h1>
          </div>
          <form className='login-cio'>
            <div className="form-group">
              <Button aria-label="cio-button" className="demo-button" onClick={() => signInAs('cio', compSel, dispatch, startTransition, navigate)}><span>Sign in as CIO</span><img className='nrl' src={images.iconArrow} alt="" /></Button> 
            </div>
            <div className="form-group">
              <Button aria-label="manager-button" className="demo-button" onClick={() => signInAs('space manager', compSel, dispatch, startTransition, navigate)}><span>Sign in as Space Manager</span><img className='nrl' src={images.iconArrow} alt="" /></Button> 
            </div>
            <div className="form-group">
              <Button aria-label="mem-button" className="demo-button" onClick={() => signInAs('member', compSel, dispatch, startTransition, navigate)}><span>Sign in as Member</span><img className='nrl' src={images.iconArrow} alt="" /></Button> 
            </div>
          </form>
        </div >
      </div >
    </section >
  )
}

export default DemoPurposeSignin;
