import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import images from "../assets/images";
import "../assets/scss/main.scss";

function DefaultRoute() {
  const navigate = useNavigate();

  //===============================================
  return (
    <div className="page-404" aria-label="default-page"> 
      {/* <div className="logo">
        <img src={images.builderLogo} alt="Builder"/>
      </div> */}
      <div className="bg-404">&nbsp;</div>
      <div className="inner-container">
        <div className="img-wrap">
          <img className="empty-box" src={images.emptyBox} alt="empty" />
        </div>
        <h3>Hmm.. page doesn’t exist</h3>
        <p>Some links might be broken here and there. </p>
        <Button data-testid='home-btn' className="dark-purple-btn" onClick={() => navigate('/dashboard')}>Go to Home</Button>
      </div>
      {/* <div>
        <h2>Oops!!!</h2>
        <p>You landed on a wrong page.</p>
        <div className="d-flex">
          <Button data-testid='goback-btn' className="me-4" onClick={() => navigate(-1)}>Go back</Button>
          <Button data-testid='home-btn' className="dark-purple-btn" className="dark-purple-btn" onClick={() => navigate('/dashboard')}>Home</Button>
        </div>
      </div> */}
    </div>
  )
}

export default DefaultRoute;
