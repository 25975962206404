import { lazy } from 'react';

export const NatashaWelcome = lazy(
  () => import('../pages/onboard/NatashaWelcome')
);
export const ProjectSelection = lazy(
  () => import('../pages/onboard/ProjectSelection')
);
export const NatashaRecommendedPlan = lazy(
  () => import('../pages/onboard/NatashaRecommendedPlan')
);
export const ChoosePlan = lazy(
  () => import('../pages/onboard/plans/ChoosePlan')
);
export const OwnerOverview = lazy(
  () => import('../pages/onboard/OwnerOverview')
);
export const ItAdminOverview = lazy(
  () => import('../pages/onboard/ItAdminOverview')
);
export const WaitingOnPayment = lazy(
  () => import('../pages/onboard/WaitingOnPayment')
);
export const MemberDashboard = lazy(
  () => import('../pages/onboard/MemberDashboard')
);

export const CheckoutPlanSelection = lazy(
  () => import('../pages/plan-checkout/CheckoutPlanSelection')
);
export const PlanBillingDetails = lazy(
  () => import('../pages/plan-checkout/PlanBillingDetails')
);
export const PlanPayment = lazy(
  () => import('../pages/plan-checkout/PlanPayment')
);

export const PaymentSuccess = lazy(
  () => import('../pages/payment-success/PaymentSuccess')
);

export const MemberOnboarding = lazy(
  () => import('../pages/member-onboarding/MemberOnboarding')
);
export const NatashaIntro = lazy(
  () => import('../pages/member-onboarding/NatashaIntro')
);
export const ProfileSetup = lazy(
  () => import('../pages/member-onboarding/ProfileSetup')
);
export const ProfilePhoto = lazy(
  () => import('../pages/member-onboarding/ProfilePhoto')
);

export const WorkspaceCustomisation = lazy(
  () => import('../pages/workspace-customisation/WorkspaceCustomisation')
);
export const CompanySetup = lazy(
  () => import('../pages/workspace-customisation/CompanySetup')
);
export const AccountName = lazy(
  () => import('../pages/workspace-customisation/AccountName')
);
export const AccountLogo = lazy(
  () => import('../pages/workspace-customisation/AccountLogo')
);

export const CreditsAndPlan = lazy(
  () => import('../pages/plan-payments/CreditsAndPlan')
);
export const PlanPaymentLogs = lazy(
  () => import('../pages/plan-payments/PlanPaymentLogs')
);
export const PlanPaymentsBillingDetails = lazy(
  () => import('../pages/plan-payments/PlanPaymentsBillingDetails')
);

export const CompanyIntegrations = lazy(
  () => import('../pages/company/CompanyIntegrations')
);
export const CompanyIntegrationsDetail = lazy(
  () => import('../pages/company/CompanyIntegrationsDetail')
);

export const MemberProfile = lazy(
  () => import('../pages/member-profile/MemberProfileNavigation')
);

export const PlaygroundBuildcard = lazy(
  () => import('../pages/building/projects/PlaygroundBuildcard')
);
export const RefineStudio = lazy(
  () => import('../pages/building/projects/RefineStudio')
);
export const MeetingSummary = lazy(
  () => import('../pages/building/projects/MeetingSummary')
);
export const KpiDetail = lazy(() => import('../pages/company/goals/KpiDetail'));

export const BuildingSpaces = lazy(
  () => import('../pages/building/BuildingSpaces')
);
export const SpaceOverview = lazy(
  () => import('../pages/building/SpaceOverview')
);
export const SpaceTeam = lazy(() => import('../pages/building/SpaceTeam'));
export const SpaceCredits = lazy(
  () => import('../pages/building/SpaceCredits')
);
export const CompanySettings = lazy(
  () => import('../pages/company/CompanySettings')
);
export const CompanyDefaultRolesPermissions = lazy(
  () => import('../pages/company/CompanyDefaultRolesPermissions')
);
export const CompanyCustomRolesPermissions = lazy(
  () => import('../pages/company/CompanyCustomRolesPermissions')
);
export const MembersDashboard = lazy(
  () => import('../pages/members/MembersDashboard')
);
export const MemberDetail = lazy(() => import('../pages/members/MemberDetail'));
