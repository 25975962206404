import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import '../../building/project.scss';
import './duplicate-buildcard.scss.scss';
import images from '../../../assets/images';
import { PROJECTS, statusColorMapping } from '../../../shared/util/constant';
import { useParams } from 'react-router-dom';
import { companyState } from '../../../shared/store/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import instance from '../../../shared/interceptor';
import { globalError, snakeCaseToSentence } from '../../../shared/util/util';
import LoaderComp from '../../../shared/shared-comp/LoaderComp';
import OverflowTooltip from '../../../shared/shared-comp/OverflowTooltip';

export default function JourneysFeaturesTable({
  mainBuildCard,
  similarBuildCards,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);

  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [data, setData] = useState(null);
  const [collapseJourneys, setCollapseJourneys] = useState(null);
  const [originalBuildCard, setOriginalBuildCard] = useState(null);
  const [loading, setLoading] = useState(false);

  const getBuildCardDetail = (buildcard) => {
    const url = `${PROJECTS.replace(':id', compSel?.id).replace(':space_id', buildcard?.space?.id)}/build_cards/${buildcard?.id}`;
    return instance.get(url);
  };

  const fetchJourneyComparisonData = async () => {
    try {
      setLoading(true);
      let res = await Promise.all([
        getBuildCardDetail(mainBuildCard),
        ...similarBuildCards.map((card) => getBuildCardDetail(card)),
      ]);
      if (res && res.length) {
        setData(formatComparisonData(res));
        setOriginalBuildCard(res[0]?.['build_card']);
        setCollapseJourneys(
          Object.fromEntries(
            res[0]?.['build_card']?.journeys?.map((_, index) => [index, false])
          )
        );
      }
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoading(false);
    }
  };

  const formatComparisonData = (buildCards) => {
    let res = {};

    buildCards?.slice(1)?.forEach((itrCard) => {
      res[itrCard?.build_card?.id] = { name: itrCard?.build_card?.name };
      let temp = {};
      buildCards[0]?.build_card?.journeys?.forEach((mainJourney) => {
        if (!Object.keys(temp).includes(mainJourney?.id)) {
          temp = { ...temp, [mainJourney?.id]: { commonFeaturesCount: 0 } };
        }

        for (const itrJourney of itrCard?.build_card?.journeys) {
          if (itrJourney?.id === mainJourney?.id) {
            mainJourney?.features?.forEach((mainFeature) => {
              let isCommon = itrJourney?.features?.some(
                (itrFeature) =>
                  itrFeature?.feature_id === mainFeature?.feature_id
              );

              if (isCommon) {
                temp[itrJourney?.id]['commonFeaturesCount'] += 1;
                temp[itrJourney?.id][mainFeature?.feature_id] = true;
              }
            });
            break;
          }
        }
        res = {
          ...res,
          [itrCard.build_card.id]: { ...res[itrCard.build_card.id], ...temp },
        };
      });
    });

    return res;
  };

  useEffect(() => {
    fetchJourneyComparisonData();
  }, [mainBuildCard, similarBuildCards]);

  //=================================================

  return (
    <div className="attributes-section">
      <div className="hover-feature-note">
        <em>
          <img src={images.infoIcon} alt="info" />
        </em>
        Hover to view feature note
      </div>
      <div className="attributes-table Journey-table" style={{ width: '100%' }}>
        {loading ? (
          <LoaderComp />
        ) : (
          <TableContainer component={Paper}>
            <Table className="collapsible-table" aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ background: '#F3EBFF' }}>
                    <div className="d-flex align-items-center">
                      <OverflowTooltip
                        tooltip={mainBuildCard?.name}
                        text={mainBuildCard?.name}
                        placement={'top'}
                      />{' '}
                      <span
                        className="status-tags"
                        style={{
                          marginLeft: '5px',
                          background:
                            statusColorMapping[mainBuildCard?.state] ??
                            '#FFAB00',
                        }}
                      >
                        {snakeCaseToSentence(mainBuildCard?.state)}
                      </span>
                    </div>
                  </TableCell>
                  {similarBuildCards?.map((card) => (
                    <TableCell key={card?.name}>
                      <div className="d-flex align-items-center">
                        <OverflowTooltip
                          tooltip={card?.name}
                          text={card?.name}
                          placement={'top'}
                        />
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {originalBuildCard &&
                  originalBuildCard?.journeys?.map((journey, index) => (
                    <>
                      <TableRow className="collapsible-row">
                        <TableCell scope="row">
                          <div className="d-flex justify-content-between align-items-center">
                            {journey?.name}
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() =>
                                setCollapseJourneys((prev) => ({
                                  ...prev,
                                  ...{ [index]: !prev[index] },
                                }))
                              }
                            >
                              {collapseJourneys[index] ? (
                                <em
                                  className="icon be-plus"
                                  style={{ color: '#6e6e6e' }}
                                />
                              ) : (
                                <img src={images.iconMinus} alt="minus" />
                              )}
                            </IconButton>
                          </div>
                        </TableCell>
                        {Object.entries(data)?.map(([cardId, journeys]) => (
                          <TableCell key={cardId} scope="row">
                            {journeys?.[journey?.id]?.commonFeaturesCount ? (
                              <>
                                <strong>
                                  {journeys?.[journey?.id]?.commonFeaturesCount}
                                </strong>{' '}
                                out of{' '}
                                <strong>{journey?.['features']?.length}</strong>{' '}
                                common features
                              </>
                            ) : (
                              '-'
                            )}
                          </TableCell>
                        ))}
                      </TableRow>

                      {journey?.features?.map((feature) => (
                        <TableRow
                          key={feature?.feature_id}
                          className="collapse-table"
                        >
                          <TableCell
                            className="blank"
                            style={{}}
                            colSpan={data ? Object.keys(data)?.length + 1 : 0}
                          >
                            <Collapse
                              in={!collapseJourneys[index]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box>
                                <Table
                                  className="inner-table"
                                  size="small"
                                  aria-label="purchases1"
                                >
                                  <TableHead style={{ display: 'none' }}>
                                    <TableRow>
                                      <TableCell></TableCell>
                                      <TableCell></TableCell>
                                      <TableCell align="right"></TableCell>
                                      <TableCell align="right"></TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell scope="row">
                                        {feature?.title}
                                        <Tooltip
                                          className="note-tooltip"
                                          placement="right"
                                          title={
                                            <span
                                              className="note-info"
                                              dangerouslySetInnerHTML={{
                                                __html: feature?.description,
                                              }}
                                            />
                                          }
                                        >
                                          <Button className="note-btn">
                                            view note
                                          </Button>
                                        </Tooltip>
                                      </TableCell>
                                      {Object.entries(data)?.map(
                                        ([cardId, journeys]) => (
                                          <TableCell key={cardId} scope="row">
                                            {journeys?.[journey?.id]?.[
                                              feature?.feature_id
                                            ] ? (
                                              <span className="icon be-tick">
                                                <Tooltip
                                                  className="note-tooltip"
                                                  placement="top"
                                                  title={
                                                    <span
                                                      className="note-info"
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          feature?.description,
                                                      }}
                                                    />
                                                  }
                                                >
                                                  <Button className="note-btn">
                                                    view note
                                                  </Button>
                                                </Tooltip>
                                              </span>
                                            ) : (
                                              <span className="icon be-close"></span>
                                            )}
                                          </TableCell>
                                        )
                                      )}
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
}
