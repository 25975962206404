import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

export const spaceCredit = createSlice({
  name: 'spaceCredit',
  initialState,
  reducers: {
    setSpaceCreditData: (state, action) => {
      return { ...state, spaceCredit: action.payload }
    }
  },
})

export const { setSpaceCreditData } = spaceCredit.actions;

export const spaceCreditState = (state) => state?.spaceCredit;

export default spaceCredit.reducer;
