import axios from 'axios';

import { getCookie } from './util/util';

let instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}api/v1/`,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use((request) => {
  request.headers['secret-key'] = process.env.REACT_APP_SECRET_KEY;

  if (request.url?.includes('signin') || request.url?.includes('signup')) {
    return request;
  }

  const userCookie = getCookie(process.env.REACT_APP_USER);
  const token = userCookie ? JSON.parse(userCookie)?.['authtoken'] : null;
  request.headers['client-login-token'] = token;

  return request;
});

instance.interceptors.response.use(
  (res: any) => {
    if (res.config.responseType === 'blob') {
      return res;
    }
    return res?.data?.message === 'success' ? res.data.data : null;
  },
  (error) => {
    if (error?.message === 'canceled') {
      return;
    }
    return Promise.reject(error ?? 'Something went wrong.');
  }
);

export default instance;
