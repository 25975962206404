import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

export const errorslice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setErrorMsg: (state, action) => {
      return { error: action.payload }
    }
  },
})

export const { setErrorMsg } = errorslice.actions;

export const errorState = (state) => state?.error;

export default errorslice.reducer;