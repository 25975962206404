import { createTheme } from '@mui/material/styles';
import '../assets/scss/_colors.scss';

const breakpointsValues = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

// eslint-disable-next-line
const customTheme = createTheme({
  breakpoints: {
    values: breakpointsValues,
  },
})

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Rubik', 'sans-serif',
    ].join(','),
    // @ts-ignore
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    color: '#3c3e49',
  },

  palette: {
    primary: {
      main: '#6200ea',
    },
    // secondary: {
    //   main: '#e82e2e',
    // },
  },

  breakpoints: {
    values: breakpointsValues,
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          display: 'flex',
          fontSize: '1.6rem',
          height: '6rem',
          borderRadius: '0.8rem',
          padding: '0 1.5rem',
          background: '#00E676',
          fontWeight: '500',
          color: '#3C3E49',
          boxShadow: 'none',
          textTransform: 'none',
          '&:hover': {
            background: '#00E676',
            boxShadow: 'none',
          },
          '&[disabled]': {
            background: '#00E676',
            color: '#3C3E49',
            opacity: '0.5',
          },
          '&.sm-btn': {
            height: '4.8rem',
            lineHeight: '4.8rem',
            '&.is-icon-btn': {
              width: '4.8rem',
              minWidth: 'inherit',
              padding: '0',
            }
          },
          '&.xs-btn': {
            height: '3.6rem',
            lineHeight: '3.6rem',
            fontSize: '1.4rem',
            '&.is-icon-btn': {
              width: '3.6rem',
              fontSize: '1.6rem',
              minWidth: 'inherit',
              padding: '0',
            }
          },
          '&.xxs-btn': {
            height: '2.8rem',
            lineHeight: '2.7rem',
            fontSize: '1.4rem',
            fontWeight: '400',
            borderRadius: '0.4rem',
          },
          '&.white-btn': {
            background: '#fff',
            color: '#6200ea',
            '&.black-text': {
              color: '#3C3E49',
              '&:hover': {
                color: '#3C3E49',
              }
            },
            '&:hover': {
              background: '#fff',
              color: '#6200ea',
              borderColor: '#B388FF',
            }
          },
          '&.purple-btn': {
            background: '#f3ebff',
            borderColor: '#f3ebff',
            color: '#3c3e49',
            border: '2px solid transparent',
            '&[disabled]': {
              color: '#afb2c0',
              borderColor: '#f3ebff',
              opacity: '0.5',
            },
            '&:hover': {
              background: '#f3ebff',
              color: '#3c3e49',
              border: '2px solid #b388ff'
            },
            '&:active': {
              background: '#b388ff',
              color: '#3c3e49',
              border: '2px solid #b388ff'
            },
            '&.has-border': {
              border: '0.1rem solid rgba(179, 136, 255, 0.3)',
              '.icon': {
                display: 'inline-flex',
              }
            }
          },
          '&.dark-purple-btn': {
            background: '#7C4DFF',
            color: '#fff',
            border: '2px solid transparent',
            '&:hover': {
              background: '#7C4DFF',
              color: '#fff',
              border: '2px solid #6200EA',
            }
          },
          '&.grey-btn': {
            background: '#E8E8E8',
            color: '#3c3e49',
            border: '2px solid transparent',
            '&:hover': {
              background: '#E8E8E8',
              color: '#3c3e49',
              border: '2px solid #B388FF',
            }
          },
          '&.green-btn': {
            background: '#00E676',
            color: '#3c3e49',
            border: '2px solid transparent',
            '&:hover': {
              background: '#00E676',
              color: '#3c3e49',
              border: '2px solid #00C853',
            }
          },
          '&.orange-btn': {
            background: '#FF8A80',
            color: '#3c3e49',
            '&:hover': {
              background: '#FF8A80',
              color: '#3c3e49',
            }
          },
          '&.red-btn': {
            background: '#FF8A80',
            color: '#262626',
            border: '2px solid transparent',
            '&:hover': {
              background: '#FF8A80',
              color: '#262626',
              border: '2px solid #FF5546',
            }
          },
          '&.dark-blue-btn': {
            background: '#7C4DFF',
            color: '#ffffff',
            border: '2px solid transparent',
            '&:hover': {
              background: '#7C4DFF',
              color: '#ffffff',
              border: '2px solid #6200EA',
            }
          },
          '&.lightred-btn': {
            background: '#fee5e2',
            color: '#3c3e49',
            '&:hover': {
              background: '#fee5e2',
              color: '#3c3e49',
            }
          },
          '.btn-icon': {
            lineHeight: '0',
            marginRight: '0.9rem',
            fontSize: '2rem',
            '&.sm': {
              fontSize: '1.4rem',
            }
          }
        },
        outlined: {
          background: '#fafbfc',
          color: '#3C3E49',
          border: '0.1rem solid rgba(179, 136, 255, 0.3)',
          '&:hover': {
            background: '#fafbfc',
            border: '0.1rem solid rgba(179, 136, 255, 0.3)',
          },
          '&[disabled]': {
            background: '#fafbfc',
            color: '#3C3E49',
            borderColor: 'rgba(179, 136, 255, 0.5)',
          },
          '&.white-border': {
            background: 'transparent',
            color: '#fff',
            border: '0.2rem solid #fff',
            '&:hover': {
              background: 'transparent',
              border: '0.2rem solid #B388FF',
            }
          },
        }
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          width: '4.8rem',
          height: '4.8rem',
          padding: '1.2rem',
          fontSize: '2.4rem',
          color: '#3C3E49',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          'em[class^="be-"]': {
            lineHeight: '0',
          }
        }
      }
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          display: 'flex'
        }
      }
    },

    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          border: '0.1rem solid #c0c3ce',
          borderRadius: '0.4rem',
          transition: 'all 0.3s ease-in',
          height: '6rem',
          '&:hover': {
            backgroundColor: '#fff',
          },
          '&:before, &:after': {
            display: 'none',
          },
          '&.Mui-focused': {
            borderColor: '#6200ea',
            backgroundColor: '#fff',
          },
          '&.Mui-disabled': {
            backgroundColor: '#F8F9FA',
            cursor: 'not-allowed',
            '&:hover': {
              backgroundColor: '#F8F9FA',
            },
            '.MuiInputBase-input': {
              'WebkitTextFillColor': '#3C3E49',
              cursor: 'not-allowed',
            }
          },
          '&.xs-input': {
            height: '3.6rem',
            input: {
              padding: '0 0.8rem',
              fontSize: '1.4rem',
              height: '3.6rem',
            },
            '.MuiInputAdornment-root': {
              marginTop: '0.1rem !important',
              marginRight: '0',
              '[class^="be"]': {
                lineHeight: '0',
              }
            }
          },
          '&.no-label': {
            input: {
              padding: '1.85rem 1.6rem',
            }
          },
          '&.sm-input': {
            height: '4.8rem',
            input: {
              padding: '0 1.6rem',
              fontSize: '1.6rem',
              height: '4.8rem',
            },
          }
        },
        input: {
          padding: '2.6rem 1.6rem 0.8rem',
          '&:before, &:after': {
            display: 'none',
          },
        },
      }
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#595959',
          transform: 'translate(1.6rem, 1.8rem)',
          fontSize: '1.6rem',
          lineHeight: '2.4rem',
        },
        shrink: {
          transform: 'translate(1.6rem, 0.9rem) scale(0.75)',
          '&.Mui-focused': {
            color: '#6200ea'
          },
        }
      }
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: '0',
        },
      }
    },


    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiAutocomplete-endAdornment': {
            top: 'calc(50% - 2.4rem)',
            right: '0.6rem !important',
          }
        }
      }
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          width: '4.2rem',
          minWidth: '4.2rem',
        }
      }
    },

    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '0.8rem',
          background: '#DFE0E6',
          borderRadius: '1rem',
          '&.sm': {
            height: '0.4rem',
          },
          '&.transparent': {
            background: 'transparent',
          },
          '&.secondary': {
            border: '0.1rem solid #3C3E49',
            borderRadius: '0.2rem',
            height: '1.2rem',
            background: 'transparent',
            '.MuiLinearProgress-bar': {
              backgroundColor: '#B388FF',
            }
          }
        }
      }
    },

    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: '0',
          width: '4.8rem',
          height: '2.4rem',
          borderRadius: '1.2rem',
          '&.sm-switch': {
            width: '4rem',
            height: '2rem',
            '.MuiSwitch-switchBase': {
              width: '1.4rem',
              height: '1.4rem',
              '&.Mui-checked': {
                transform: 'translateX(2rem)',
              }
            }
          },
          '.MuiSwitch-switchBase': {
            padding: '0',
            height: '1.8rem',
            width: '1.8rem',
            top: '0.3rem',
            left: '0.3rem',
            display: 'block',
            background: '#fff',
            '&:hover': {
              background: '#fff',
            },
            '&.Mui-checked': {
              background: '#fff',
              color: '#fff',
              transform: 'translateX(2.4rem)',
              '&:hover': {
                background: '#fff',
              },
              '+ .MuiSwitch-track': {
                backgroundColor: '#00E676',
                opacity: '1',
              }
            }
          },
          '.MuiSwitch-track': {
            background: '#83889E',
            opacity: '1',
          }
        }
      }
    },

    MuiSlider: {
      styleOverrides: {
        root: {
          height: '0.2rem',
          padding: '0.8rem 0',
          '.MuiSlider-rail': {
            backgroundColor: '#B388FF',
            opacity: '1',
          },
          '.MuiSlider-thumb': {
            height: '1.6rem',
            width: '1.6rem',
          }
        }
      }
    },

    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-filled': {
            '&.purple': {
              background: '#6200EA',
            },
            '&.light-purple': {
              background: '#F3EBFF',
            },
            '&.yellow': {
              background: '#FFAB00',
            },
            '&.grey': {
              background: '#676B7E',
            },
            '&.green': {
              background: '#00C853',
            },
          }
        }
      }
    }

  },

});
