import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { startTransition } from 'react';
import {
  permissionState,
  userPermissionState,
} from '../store/slices/userSlice';
import {
  formatDate,
  getBcState,
  getCardLable,
  isAllowedTo,
  redirectTo,
} from '../util/util';
import CardEntityTags from './CardEntityTags';
import GoalsTags from './GoalsTags';
import OverflowTooltip from './OverflowTooltip';

function BuildcardComp({
  card,
  entity,
  idx,
  spaceGoals,
  searchSpaceGoalList,
  updateGoals,
  setShowSearchTags,
  deleteTag,
  tagList,
  addTags,
  tagChangeHandler,
  shouldNavigate = true,
}) {
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);
  const navigate = useNavigate();

  return (
    <div
      className="card-template"
      style={shouldNavigate ? {} : { marginBottom: 0 }}
    >
      <div
        data-testid="build-card-body"
        className="card-body"
        onClick={() =>
          shouldNavigate
            ? startTransition(() => {
                redirectTo(
                  { id: card?.space_id ?? card?.space?.id },
                  card,
                  navigate
                );
              })
            : null
        }
        style={{ cursor: 'pointer' }}
      >
        <div className="tag-container has-right-content">
          <Stack direction="row" spacing={1}>
            <Chip
              label={getCardLable(card?.state)}
              className={getBcState(card?.state)}
            />
          </Stack>
          {isAllowedTo(
            'read',
            'space_goal',
            permissions,
            entity,
            userPermissions
          ) && (
            <GoalsTags
              {...{ card, idx, spaceGoals, searchSpaceGoalList, updateGoals }}
            />
          )}
        </div>

        <div className="inner-container">
          <div className="left">
            <div className="card-title-container">
              <OverflowTooltip
                text={card?.name}
                tooltip={card?.name}
                placement={'top'}
                className={'card-name'}
              />
              <div className="date-container">
                {card?.state == 'draft' || card?.state == 'pending_approval' ? (
                  card?.weeks ? (
                    <span>{card?.weeks} weeks</span>
                  ) : card?.duration ? (
                    <span>{card?.duration} weeks</span>
                  ) : (
                    ''
                  )
                ) : (
                  <>
                    <span className="title">DELIVERY: </span>
                    <span>
                      {card?.delivery_date
                        ? formatDate(card?.delivery_date)
                        : '-'}
                    </span>
                  </>
                )}
              </div>
              <div className="sub-heading">
                Lead: {card?.created_by ?? card?.creator?.name}
              </div>
            </div>
          </div>
          <div className="right">
            <div className="progress-circle">
              <Tooltip
                className="progress-tooltip-popover"
                placement="bottom"
                enterTouchDelay={0}
                title={
                  <div className="credits">
                    <span>{card?.completion ?? 0}%</span>
                  </div>
                }
              >
                <span>
                  {!['draft', 'pending_approval', 'ready_for_kickoff'].includes(
                    card?.state
                  ) && (
                    <div className="progress-with-bg">
                      <CircularProgress
                        size={40}
                        variant="determinate"
                        className="bg-progress"
                        value={100}
                      />
                      <CircularProgress
                        size={40}
                        variant="determinate"
                        value={card?.completion ?? 0}
                      />
                      <span className="progress-val">
                        {Math.ceil(card?.completion ?? 0)}%
                      </span>
                    </div>
                  )}
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
        {isAllowedTo(
          'read',
          'entity_tag',
          permissions,
          entity,
          userPermissions
        ) && (
          <div className="card-tags-wrap">
            <CardEntityTags
              {...{
                card,
                setShowSearchTags,
                deleteTag,
                tagList,
                addTags,
                tagChangeHandler,
              }}
            />
          </div>
        )}
      </div>
      <div className="card-footer">
        <div className="content-box">
          <span className="title">Features</span>
          <div className="count">{card?.number_of_features}</div>
        </div>
        <div className="content-box">
          <span className="title">Platforms</span>
          <div className="count">
            {card?.number_of_platforms ?? card?.platforms?.length}
          </div>
        </div>
        <div className="content-box">
          <OverflowTooltip
            tooltip={card?.currency?.name}
            text={card?.currency?.name}
            placement={'right'}
            className={'title'}
          />
          <div className="count">
            {card?.final_cost?.toString()?.length > 7 ? (
              <OverflowTooltip
                placement="top"
                style={{ width: '70px', cursor: 'pointer', display: 'block' }}
                text={card?.final_cost ?? '0'}
                tooltip={card?.final_cost ?? 0}
              />
            ) : (
              (card?.final_cost ?? '0')
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuildcardComp;
