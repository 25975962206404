/* eslint eqeqeq: 0 */
import Popper from '@mui/material/Popper';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import images from '../../assets/images';
import consumer from '../../shared/cable';
import useOutSideClick from '../../shared/hooks/UseOutsideClick';
import instance from '../../shared/interceptor';
import {
  getCurrentSpaceDetail,
  getSpaceCreditData,
} from '../../shared/services/common.service';
import LoaderComp from '../../shared/shared-comp/LoaderComp';
import OverflowTooltip from '../../shared/shared-comp/OverflowTooltip';
import UserProfileTooltip from '../../shared/shared-comp/UserProfileTooltip';
import { currentSpaceState } from '../../shared/store/slices/companySlice';
import { spaceCreditState } from '../../shared/store/slices/spaceCreditSlice';
import { setSuccessMsg } from '../../shared/store/slices/successSlice';
import {
  companyState,
  permissionState,
  userPermissionState,
} from '../../shared/store/slices/userSlice';
import { COMPANY, SPACES } from '../../shared/util/constant';
import {
  debounce,
  getFullname,
  getInitials,
  globalError,
  isAllowedTo,
  navigateToSpaceOrProject,
} from '../../shared/util/util';
import SpaceCreditModals from './SpaceCreditModals';
import TagResultSection from './TagResultSection';
import './spaces.scss';

function Summary() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const currentSpaceSel = useSelector(currentSpaceState);
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);
  const spaceCreditSel = useSelector(spaceCreditState);

  const [tagList, setTagList] = useState([]);
  const [activities, setActivities] = useState([]);
  const [showSearchTags, setShowSearchTags] = useState(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [owner, setOwner] = useState(null);
  const [tags, setTags] = useState(null);
  const [openRequestCreditDialogue, setOpenRequestCreditDialogue] =
    useState(null);
  const [spaceCreditOverview, setSpaceCreditOverview] = useState(null);
  const [
    openCreditRequestHistoryDialogue,
    setOpenCreditRequestHistoryDialogue,
  ] = useState(false);
  const pendingSpaceCreditRequest =
    spaceCreditSel?.['spaceCredit']?.[0]?.['space_credit']?.[
      'pending_space_credit_request'
    ];
  const [emptyRecent, setEmptyRecent] = useState(false);
  const [popperAnchorEl, setPopperAnchorEl] = useState(null);
  const [activityLoading, setActivityLoading] = useState(true);
  const [tagLoading, setTagLoading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (compSel?.id) {
      getActivities(signal);
    }

    return () => {
      controller.abort();
    };
  }, [compSel?.id]);

  useEffect(() => {
    setOwner(currentSpaceSel?.owner);
    setTags(currentSpaceSel?.entity_tags);
  }, [currentSpaceSel?.owner?.id, currentSpaceSel?.entity_tags?.length]);

  useEffect(() => {
    consumer.subscriptions.create(
      {
        channel: `ActivityChannel`,
        channel_id: `space_${currentSpaceSel?.id}`,
      },
      {
        connected: () => null,
        disconnected: () => console.log('disconnected for summary'),
        received: (data) => {
          activities.unshift({
            id: data?.body?.id,
            value: data?.body?.data?.value,
            created_date: data?.body?.data?.created_date,
            created_at: data?.body?.data?.created_at,
            user: data?.body?.data?.user_details,
          });
        },
      }
    );

    return () => {
      consumer.disconnect();
    };
  }, [activities?.length]);

  useEffect(() => {
    if (
      isAllowedTo(
        'update',
        'space_credit',
        permissions,
        entity,
        userPermissions
      ) &&
      currentSpaceSel?.active_space_credit_id &&
      compSel?.id
    ) {
      getSpaceCreditData(compSel, currentSpaceSel, dispatch);
    }
  }, [
    currentSpaceSel?.active_space_credit_id,
    pendingSpaceCreditRequest?.id,
    pendingSpaceCreditRequest?.credit_requested,
    compSel?.id,
  ]);

  useEffect(() => {
    setSpaceCreditOverview(
      spaceCreditSel?.['spaceCredit']?.[0]?.['space_credit']
    );
  }, [spaceCreditSel]);

  const getEntityId = () => {
    return currentSpaceSel?.id;
  };

  const entity = {
    type: 'Space',
    id: getEntityId(),
  };

  const isAllowedToCreateEntityTag = isAllowedTo(
    'update',
    'entity_tag',
    permissions,
    entity,
    userPermissions
  );
  const isAllowedToDestroyEntityTag = isAllowedTo(
    'destroy',
    'entity_tag',
    permissions,
    entity,
    userPermissions
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    getAllTags();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getAllTags = async (query?) => {
    try {
      let url = `${COMPANY}/${compSel?.id}/tags`;
      if (query) {
        url += `?q=${encodeURIComponent(query?.toLowerCase())}`;
      }
      let tagsRes = await instance.get(url);
      setTagList(tagsRes['tags']);
    } catch (e) {
      globalError(dispatch, e);
    }
  };

  const addTag = async (tagName) => {
    try {
      tagName = tagName?.trim()?.toLowerCase();
      if (!tagName) return;
      setTagLoading(true);
      let url = `${COMPANY}/${compSel?.id}/entity_tags`;
      let payload = {
        entity_tag: {
          name: encodeURIComponent(tagName),
          taggable_id: currentSpaceSel?.id,
          taggable_type: 'Space',
        },
      };
      await instance.post(url, payload);
      getCurrentSpaceDetail(dispatch, compSel?.id, currentSpaceSel?.id);
      handleClose();
      dispatch(setSuccessMsg('Tag added successfully.'));
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setTagLoading(false);
    }
  };

  const tagChangeHandler = debounce((e) => {
    if (e.key === 'Enter') {
      addTag(e.target.value);
      handleClose();
    } else {
      getAllTags(e.target['value']);
    }
  });

  const handleDelete = async (e, tagId) => {
    if (!isAllowedToDestroyEntityTag) return;

    if (!e.detail || e.detail == 1) {
      try {
        setTagLoading(true);
        const url = `${COMPANY}/${compSel?.id}/entity_tags/${tagId}`;
        await instance.delete(url);
        getCurrentSpaceDetail(dispatch, compSel?.id, currentSpaceSel?.id);
        dispatch(setSuccessMsg('Tag deleted successfully.'));
      } catch (e) {
        globalError(dispatch, e);
      } finally {
        setTagLoading(false);
      }
    }
  };

  const getActivities = async (signal) => {
    try {
      setActivityLoading(true);
      let url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/activities?page=1`;
      if (url) {
        const res = await instance.get(url, { signal });
        if (res) {
          const recent = res?.['activities'].filter((item) =>
            moment(item?.created_at).isAfter(moment().subtract(7, 'd'))
          );
          if (recent?.length) {
            setActivities(recent.splice(0, 4));
          } else {
            setEmptyRecent(true);
          }
        }
      }
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setActivityLoading(false);
    }
  };

  const renderMembersList = () => {
    const colorsList = ['#E1C3FF', '#FFC7B8', '#C8F8FF', ''];
    const length = currentSpaceSel?.members
      ? Math.min(currentSpaceSel?.members.length, 4)
      : 0;
    const res = [];

    currentSpaceSel?.members?.forEach((member, idx) => {
      if (idx < length - 1) {
        res.push(
          member?.profile_picture?.profile_picture_url ? (
            <em key={member?.id} className="initials">
              <img
                src={member?.profile_picture?.profile_picture_url}
                alt="user profile"
              />
            </em>
          ) : (
            <span
              key={member?.id}
              className="initials"
              style={{ background: colorsList[idx] }}
            >
              {getInitials(member?.first_name ?? member?.email)}
            </span>
          )
        );
      } else if (idx === length - 1) {
        let innerText = '';
        if (currentSpaceSel?.members?.length === idx + 1) {
          innerText = member?.profile_picture?.profile_picture_url ? (
            <em key={member?.id} className="initials">
              <img
                src={member?.profile_picture?.profile_picture_url}
                alt="user profile"
              />
            </em>
          ) : (
            getInitials(member.first_name ?? member.email)
          );
        } else {
          innerText = `+${currentSpaceSel?.members?.length - 3}`;
        }
        res.push(
          <span
            key="last"
            className="initials"
            style={{ background: colorsList[idx] }}
          >
            {innerText}
          </span>
        );
      }
    });

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>{res}</div>
    );
  };

  const showUserTooltip = (e, activity) => {
    e?.stopPropagation();
    if (
      (e?.target as any)?.nodeName.toLowerCase() === 'strong' &&
      (!(e?.target as any)?.dataset?.spaceId ||
        !(e?.target as any)?.dataset?.buildcardId)
    ) {
      setPopperAnchorEl({ [activity?.id]: e.currentTarget });
    } else {
      setPopperAnchorEl(null);
    }
  };

  const activityRef = useOutSideClick((e) => {
    if ((e?.target as any)?.nodeName.toLowerCase() === 'strong') {
      return;
    }
    setPopperAnchorEl(null);
  });

  const getUserNameClass = (memberListLength) => {
    if (memberListLength > 3) {
      return 'more-members';
    } else if (memberListLength > 2) {
      return 'three-member';
    } else if (memberListLength > 1) {
      return 'two-member';
    } else if (memberListLength > 0) {
      return 'one-member';
    } else {
      return 'full-width';
    }
  };
  //=========================================================

  return (
    <aside className="summary" aria-label="summary-section">
      {/* <div className='content-row'>
        <div className='title'>Space</div>
        <div className='chips'>
          <Stack direction="row" spacing={1}>
            <Chip label={currentSpaceSel?.name} />
          </Stack>
        </div>
      </div> */}

      {false && (
        <div className="content-row base-card-wrap d-none">
          <div className="base-card">
            <div className="card-top">
              <span>Today | 04:30 PM to 05:00 PM</span>
              <span className="three-dots">
                <em className="icon be-vertical-dots"></em>
              </span>
            </div>
            <div className="card-title">Warehouse Scanner Ideation</div>
            <div className="sub-heading">
              <img src={images.iconEnvelope} alt="" /> Sub heading
            </div>
            <div className="button-wrap">
              <Button
                className="xs-btn purple-btn btn-join"
                variant="contained"
              >
                Join
              </Button>
              <Button
                variant="outlined"
                className="xs-btn is-icon-btn be-link-chain"
              ></Button>
            </div>
          </div>
        </div>
      )}

      {/* <div className='credits'>
        <div className='credit-balance'>
          <span className='title'>Credit Balance</span>
          <div className='count p-0'>405/1000</div>
        </div>
        <div className='request-credits'>
          <Link to="/">Request Credits</Link>
        </div>
      </div> */}

      {false && currentSpaceSel?.name && (
        <div className="content-row">
          <div className="title space">Space</div>
          <div className="chips">
            <Stack direction="row" className="purple-chips" spacing={1}>
              <Chip label={currentSpaceSel?.name} />
            </Stack>
          </div>
          {/* <div className='space-name'>{ currentSpaceSel?.name }</div> */}
        </div>
      )}
      {
        <div className="content-row owner-row">
          <div
            className={`${currentSpaceSel?.members?.length > 0 ? '' : 'full-width'}`}
          >
            <div className="title">
              Owner <span></span>
            </div>
            <div className="user-link">
              <span className="user-avatar">
                {owner ? (
                  owner?.profile_picture?.profile_picture_url ? (
                    <img
                      src={owner?.profile_picture?.profile_picture_url}
                      alt="Name"
                    />
                  ) : (
                    getInitials(owner?.first_name ?? owner?.email)
                  )
                ) : (
                  'N'
                )}
              </span>
              <div
                className={`user-name ${getUserNameClass(currentSpaceSel?.members?.length ?? 0)}`}
              >
                {/* <OverflowTooltip  */}
                {owner ? (
                  owner.first_name || owner.last_name ? (
                    <div className="truncated-text">
                      <OverflowTooltip
                        placement={'top'}
                        text={`${owner.first_name} ${owner.last_name}`}
                        tooltip={
                          `${owner.first_name} ${owner.last_name}` ?? 'No Owner'
                        }
                      />
                    </div>
                  ) : (
                    <div className="truncated-text">
                      <OverflowTooltip
                        placement={'top'}
                        text={owner?.email}
                        tooltip={owner?.email}
                      />
                    </div>
                  )
                ) : (
                  <div className="truncated-text">No Owner</div>
                )}
              </div>
            </div>
          </div>
          {currentSpaceSel?.members?.length > 0 && (
            <div>
              <div className="title text-right">Members</div>
              {renderMembersList()}
            </div>
          )}
        </div>
      }

      <div className="content-row credit-score">
        <div className="title text-right">
          Credit used
          {isAllowedTo(
            'update',
            'space_credit',
            permissions,
            entity,
            userPermissions
          ) && (
            <Link
              className="primary-link"
              aria-label="req-credits-btn"
              to="#"
              onClick={() => setOpenRequestCreditDialogue(spaceCreditOverview)}
            >
              Request Credits
            </Link>
          )}
        </div>
        <div className="right">
          <div className="progress-circle">
            <Tooltip
              className="progress-tooltip-popover"
              placement="bottom"
              enterTouchDelay={0}
              title={
                <div className="credits">
                  <span>
                    {Math.ceil(currentSpaceSel?.credit_consumed_percentage)}%
                  </span>
                </div>
              }
            >
              <span>
                <div className="progress-with-bg">
                  <CircularProgress
                    size={60}
                    variant="determinate"
                    className="bg-progress"
                    value={100}
                  />
                  <CircularProgress
                    size={60}
                    variant="determinate"
                    value={Math.ceil(
                      currentSpaceSel?.credit_consumed_percentage
                    )}
                  />
                  <span className="progress-val">
                    {currentSpaceSel?.credit_consumed_percentage
                      ? Math.ceil(currentSpaceSel?.credit_consumed_percentage)
                      : 0}
                    %
                  </span>
                </div>
              </span>
            </Tooltip>
          </div>
        </div>
      </div>

      {currentSpaceSel?.company_goals?.length > 0 && (
        <div className="content-row">
          <div className="title">Business goals</div>
          <div className="tags">
            <Stack direction="row" className="purple-chips" spacing={1}>
              {currentSpaceSel?.company_goals?.map((goal) => (
                <Chip
                  key={goal?.id}
                  label={goal?.name}
                  deleteIcon={
                    <span
                      data-testid="close-tag-btn"
                      className="icon be-close-dark"
                    ></span>
                  }
                />
              ))}
            </Stack>
          </div>
        </div>
      )}

      {isAllowedTo(
        'read',
        'entity_tag',
        permissions,
        entity,
        userPermissions
      ) && (
        <div className="content-row">
          <div className="title-wrap">
            <div className="title">Tags</div>
            {isAllowedToCreateEntityTag && (
              <Tooltip
                placement="top-end"
                arrow
                title="Add tag"
                classes={{ popper: 'info-tooltip' }}
              >
                <Button
                  data-testid="menu-btn"
                  className="xs-btn purple-btn is-icon-btn"
                  variant="contained"
                  onClick={handleClick}
                >
                  +
                </Button>
              </Tooltip>
            )}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              disableRestoreFocus
              open={open}
              onClose={handleClose}
              className="search-tag-dropdown-menu"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {isAllowedToCreateEntityTag && (
                <li>
                  <FormControl>
                    {/* @ts-ignore */}
                    <FilledInput
                      autoFocus
                      inputProps={{ 'data-testid': 'searchTag' }}
                      type="text"
                      className="xs-input search-tag-input"
                      placeholder="Search or create new"
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      onKeyDown={tagChangeHandler}
                    />
                  </FormControl>
                </li>
              )}
              {tagList
                ?.filter(
                  (tag) =>
                    !tags.map((entity) => entity?.name)?.includes(tag?.name)
                )
                ?.map((tag) => {
                  return (
                    <li key={tag?.id} onClick={handleClose}>
                      <Stack
                        direction="row"
                        className="purple-chips"
                        spacing={1}
                      >
                        <Chip
                          data-testid="add-chip"
                          label={
                            <OverflowTooltip
                              text={tag?.name}
                              tooltip={tag?.name}
                              placement={'top'}
                            />
                          }
                          onClick={() => addTag(tag?.name)}
                        />
                      </Stack>
                    </li>
                  );
                })}
            </Menu>
          </div>
          <div className="tags">
            {!tags?.length && (
              <div className="empty-tags-wrapper">
                <em className="empty-icon">
                  <img src={images.emptyTags} alt="Empty tag" />
                </em>
                <p className="empty-text">Your tags will appear here.</p>
              </div>
            )}

            <Stack direction="row" className="purple-chips" spacing={1}>
              {tags?.map((item) => (
                <Chip
                  onClick={() => setShowSearchTags(item)}
                  data-testid="delete-chip"
                  key={item?.id}
                  label={item?.name}
                  onDelete={(e) => handleDelete(e, item?.id)}
                  deleteIcon={
                    isAllowedToDestroyEntityTag ? (
                      <span
                        data-testid="close-tag-btn"
                        className="icon be-close-dark"
                      ></span>
                    ) : (
                      <></>
                    )
                  }
                />
              ))}
              {tagLoading && (
                <LoaderComp
                  borderLessStyle={{
                    display: 'inline-block',
                    marginLeft: '2rem',
                  }}
                />
              )}
            </Stack>
          </div>
        </div>
      )}
      <div className="content-row">
        <div className="title">Recent activity</div>
        <div className="recent-activity">
          {activityLoading && <LoaderComp />}
          {!activityLoading && emptyRecent && (
            <div className="empty-tags-wrapper">
              <em className="empty-icon">
                <img src={images.noActivity} alt="Empty Activity" />
              </em>
              <p className="empty-text">No recent activity.</p>
            </div>
          )}

          {!activityLoading &&
            activities?.length > 0 &&
            activities?.map((activity) => {
              return (
                <div className="user-link" key={activity?.id}>
                  <span className="user-avatar">
                    {activity?.user?.profile_picture?.profile_picture_url ? (
                      <img
                        src={
                          activity?.user?.profile_picture?.profile_picture_url
                        }
                        alt="user profile"
                      />
                    ) : (
                      <span className="initials">
                        {getInitials(
                          getFullname(
                            activity?.user?.first_name,
                            activity?.user?.last_name
                          )
                        )}
                      </span>
                    )}
                  </span>
                  <div className="user-detail">
                    <div className="basic-info" ref={activityRef}>
                      <div
                        onClick={(e) => {
                          (e?.target as any)?.nodeName.toLowerCase() ===
                            'strong' &&
                          ((e?.target as any)?.dataset?.spaceId ||
                            (e?.target as any)?.dataset?.buildCardId)
                            ? navigateToSpaceOrProject(e, navigate)
                            : showUserTooltip(e, activity);
                        }}
                        className="htmlContent"
                        dangerouslySetInnerHTML={{ __html: activity?.value }}
                      ></div>
                      <Popper
                        style={{ zIndex: 99 }}
                        disablePortal={true}
                        placement="top-end"
                        open={Boolean(popperAnchorEl?.[activity?.id])}
                        anchorEl={popperAnchorEl?.[activity?.id]}
                      >
                        <UserProfileTooltip {...{ user: activity?.user }} />
                      </Popper>
                    </div>
                    <span className="time">{activity?.created_date}</span>
                  </div>
                  {/* <div className="menu-trigger">
                <em className="trigger-icon be-vertical-dots"></em>
              </div> */}
                </div>
              );
            })}
        </div>
      </div>

      {/* Tag result section */}
      {showSearchTags && (
        <TagResultSection {...{ showSearchTags, setShowSearchTags }} />
      )}
      {/* Tag result section end */}

      <SpaceCreditModals
        {...{
          openRequestCreditDialogue,
          setOpenRequestCreditDialogue,
          openCreditRequestHistoryDialogue,
          setOpenCreditRequestHistoryDialogue,
        }}
      />
    </aside>
  );
}

export default memo(Summary);
