import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';

import images from '../../../assets/images';
import { ON_BOARDING_WAITING_PAYMENT } from '../../../shared/util/constant';


function PurchaseOrderSentDialog (props) {
  const navigate = useNavigate();

  const handleModalClose = (e, reason) => {
    if (reason && reason == "backdropClick") return;
    props.setSharePurchaseSent(false);
  }

  //===========================================================================

  return (
      <Dialog
      open={props.sharePurchaseSent}
      onClose={handleModalClose}
    >
      <DialogContent>
        <span className="modal-close be-close" onClick={() => { props.setError(''); props.setSharePurchaseSent(false); navigate(ON_BOARDING_WAITING_PAYMENT) }}></span>
        <div className="modal-body">
          <div className="purchase-order-sent-content">
            <em className="icon"><img src={images.roundedTickGreen} alt="" /></em>
            <div className="modal-title">
              {props.financeEmail && <h2>Purchase order sent to <span className="email">{props.financeEmail}</span></h2>}
            </div>
            <p>In the next step, your finance member will initiate the payment to unlock the potentials of Builder Enterprise.
              Meanwhile, check out how you can superpower your company.</p>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button variant="outlined" className='sm-btn' onClick={props.resendPurchaseOrder}>Resend purchase order</Button>
          <Button variant="contained" className='sm-btn' onClick={props.navigateToOverview}>Go to overview</Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default PurchaseOrderSentDialog;