import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

export const successSlice = createSlice({
  name: 'success',
  initialState,
  reducers: {
    setSuccessMsg: (state, action) => {
      return { success: action.payload }
    }
  },
})

export const { setSuccessMsg } = successSlice.actions;

export const successState = (state) => state?.success;

export default successSlice.reducer;
