import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import instance from '../../../shared/interceptor';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { companyState } from '../../../shared/store/slices/userSlice';
import { SPACES } from '../../../shared/util/constant';
import { globalError, setLoader } from '../../../shared/util/util';

function DeleteMeetingDialog({ deleteMeeting, handleModalClose }) {
  const params = useParams();
  const dispatch = useDispatch();
  const companySel = useSelector(companyState);

  const handleDeleteMeeting = async () => {
    try {
      setLoader(dispatch, true);
      const url = `${SPACES.replace(':id', companySel?.id)}/${params?.id}/meetings/${deleteMeeting?.id}`;
      await instance.delete(url);
      dispatch(setSuccessMsg('Meeting has been deleted successfully.'));
      handleModalClose(null, null, true, deleteMeeting?.id);
    } catch (err) {
      globalError(dispatch, err);
    } finally {
      setLoader(dispatch, false);
    }
  };

  //=============================================
  return (
    <Dialog
      open={!!deleteMeeting}
      onClose={handleModalClose}
      className="call-may-team-dialogue"
    >
      <DialogContent className="" aria-label="archive-modal">
        {/* <span className="modal-close be-close" onClick={handleModalClose}></span> */}
        <div className="modal-body">
          <div className="modal-title">
            <h2 aria-label="revoke-heading">Delete meeting</h2>
            <p>Are you sure you want to delete the meeting?</p>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button
            data-testid="close-delete-meeting"
            variant="contained"
            className="sm-btn grey-btn"
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            data-testid="delete-meeting"
            variant="contained"
            aria-label="archive-confirm"
            className="sm-btn green-btn"
            onClick={handleDeleteMeeting}
          >
            Yes, delete
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteMeetingDialog;
