import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import images from '../../../assets/images';
import AllocateCreditDialog from '../../../components/dialog/company-credit-dialog/AllocateCreditDialog';
import FeedbackCreditDialog from '../../../components/dialog/company-credit-dialog/FeedbackCreditDialog';
import useOutSideClick from '../../../shared/hooks/UseOutsideClick';
import instance from '../../../shared/interceptor';
import LoaderComp from '../../../shared/shared-comp/LoaderComp';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { companyState } from '../../../shared/store/slices/userSlice';
import { COMPANY } from '../../../shared/util/constant';
import { globalError, makeDoubleDigit } from '../../../shared/util/util';
import { creditsGraph } from '../../dashboard/dashboardGraphData';

function ManageCredit({
  spaceCredits,
  plan,
  graphData,
  getCreditPlanSub,
  getSpaceCredit,
}) {
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);

  const [connectWithExpert, setConnectWithExpert] = useState(null);
  const [companyApproved, setCompanyApproved] = useState(null);
  const [isEditable, setIsEditable] = useState(null);
  const [searchSpaceCredits, setSearchSpaceCredits] = useState([]);
  const [error, setError] = useState(null);
  const [searchVal, setSearchVal] = useState('');
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [creditValueLoading, setCreditValueLoading] = useState(null);

  const inputRef = useOutSideClick((e) => {
    e?.stopPropagation();
    if (
      (e?.target as any)?.nodeName?.toLowerCase() == 'button' ||
      (e?.target as any)?.nodeName?.toLowerCase() == 'input'
    ) {
      return;
    }
    setIsEditable(null);
  });

  useEffect(() => {
    setSearchSpaceCredits(spaceCredits);
  }, [spaceCredits]);

  const setCreditValue = async (e, credit) => {
    if (['e', 'E', '.', '-', '+'].includes(e.key)) {
      e.preventDefault();
    }
    let val = +e?.target?.value;
    try {
      if (e?.key == 'Enter') {
        if (val < credit?.consumed_credits) {
          globalError(dispatch, {
            message: 'Deallocation of credit cannot be less than used credits',
          });
          return setError(true);
        }
        if (typeof val === 'number' && val > -1) {
          setCreditValueLoading({ [credit?.id]: true });
          setIsEditable({ [credit?.id]: false });
          let url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${credit?.plan_credit_subscription_id}/space_credits/${credit?.id}`;
          let payload = {
            space_credit: {
              allocated_credits: val.toFixed(2),
            },
          };
          await instance.patch(url, payload);
          dispatch(setSuccessMsg('Credit allocated successfully'));
          getCreditPlanSub();
          // getSpaceCredit();
          const target = searchSpaceCredits.find(
            (obj) => obj.id === credit?.id
          );
          Object.assign(target, { ...target, allocated_credits: val });
          setSearchSpaceCredits(searchSpaceCredits);
          setSearchVal('');
        } else {
          setError(true);
          globalError(dispatch, { message: 'Enter positive numeric value' });
        }
      }
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setCreditValueLoading({ [credit?.id]: false });
    }
  };

  const searchSpace = (e) => {
    let val = e?.target?.value;
    setSearchVal(val);
    if (val) {
      setSearchSpaceCredits(
        spaceCredits?.filter((item) =>
          item?.space?.name?.toLowerCase()?.includes(val?.toLowerCase())
        )
      );
    } else {
      setSearchSpaceCredits(spaceCredits);
    }
  };

  const handleClose = (e, render = false) => {
    setConnectWithExpert(null);
    setCompanyApproved(null);
    setSelectedSpace(null);
    if (render) {
      getCreditPlanSub();
      getSpaceCredit();
    }
  };

  //=========================================================
  return (
    <div data-testid="manage-credit-section">
      <div className="right-filter">
        <div className="form-group">
          <FormControl variant="filled">
            {/* @ts-ignore */}
            <FilledInput
              inputProps={{ 'data-testid': 'spaceSearch' }}
              type="text"
              className="xs-input search-input-wrap"
              placeholder="Search spaces"
              startAdornment={
                <InputAdornment position="start">
                  <em className="be-search"></em>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end" className="search-icon-wrap">
                  {searchVal && (
                    <img
                      style={{ cursor: 'pointer' }}
                      src={images.iconCrossCircle}
                      alt=""
                      onClick={() => {
                        setSearchVal('');
                        setSearchSpaceCredits(spaceCredits);
                      }}
                    />
                  )}
                </InputAdornment>
              }
              onChange={searchSpace}
              value={searchVal}
            />
          </FormControl>
        </div>
      </div>

      <div className="manage-credits-row">
        <div className="manage-credits-col-8">
          {!searchSpaceCredits?.length && searchVal && (
            <div className="card manage-card no-search-results">
              <em className="icon sm">
                <img src={images.emptyBox} alt="" />
              </em>
              <h6>No results found</h6>
              <p>{searchVal} Space does not exist</p>
            </div>
          )}
          {searchSpaceCredits?.map((credits) => (
            <div key={credits?.id}>
              <div className="card manage-card">
                <div className="requested-block manage-block">
                  <div className="user-link">
                    <div className="user-icon ">
                      <div
                        className="users-box org"
                        style={{ backgroundColor: credits?.space?.color }}
                      >
                        <span className="icon be-multiple-users"></span>
                      </div>
                      <h5 className="truncated-text">{credits?.space?.name}</h5>
                    </div>

                    <div className="credits-edit" ref={inputRef}>
                      Allocated Credits:
                      {creditValueLoading?.[credits?.id] && <LoaderComp />}
                      {isEditable?.[credits?.id] ? (
                        <>
                          <span className="editable-input-box">
                            <FilledInput
                              data-testid="credits-input-field"
                              type="number"
                              className={error ? 'input-error' : ''}
                              defaultValue={credits?.allocated_credits}
                              onChange={() => setError(null)}
                              onKeyDown={(e) => setCreditValue(e, credits)}
                            />
                          </span>
                          <div className="d-flex justify-content-end press w-100">
                            Press “enter” to save the credits
                          </div>
                        </>
                      ) : (
                        <>
                          <h2>
                            {!creditValueLoading?.[credits?.id] &&
                              credits?.allocated_credits
                                .toFixed(2)
                                .replace(/\.00$/, '')}
                          </h2>
                          <Button
                            aria-label="edit-allocated-credits-btn"
                            onClick={() =>
                              setIsEditable({ [credits?.id]: true })
                            }
                            data-testid="wb-btn"
                            variant="outlined"
                            className="xs-btn is-icon-btn be-edit purple-btn"
                          ></Button>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="progressbar-num">
                    {' '}
                    <strong>{credits?.consumed_credits}</strong> used |{' '}
                    <strong>
                      {(credits?.allocated_credits - credits?.consumed_credits)
                        .toFixed(2)
                        .replace(/\.00$/, '')}
                    </strong>{' '}
                    balance
                  </div>
                  <div className="progress-bar">
                    <Box sx={{ width: '100%' }}>
                      <LinearProgress
                        variant="determinate"
                        value={
                          credits?.allocated_credits
                            ? (credits?.consumed_credits /
                                credits?.allocated_credits) *
                              100
                            : 0
                        }
                      />
                    </Box>
                  </div>
                </div>
              </div>
              {credits?.pending_space_credit_request && (
                <div className="space-manager">
                  <div className="left-col">
                    <div className="warning">
                      <img src={images.iconWarning} alt="" />
                    </div>{' '}
                    <strong>
                      {credits?.pending_space_credit_request?.credit_requested}{' '}
                      credits
                    </strong>{' '}
                    requested by Space Manager{' '}
                    <span className="icon">
                      <Tooltip
                        key={credits?.id}
                        placement="top"
                        arrow
                        title={
                          credits?.pending_space_credit_request?.request_note
                        }
                        classes={{ popper: 'info-tooltip' }}
                      >
                        <img src={images.iconQuestion} alt="" />
                      </Tooltip>
                    </span>
                  </div>
                  <div className="right-col">
                    <a
                      onClick={() =>
                        setConnectWithExpert(
                          credits?.pending_space_credit_request
                        )
                      }
                    >
                      Reject & give feedback
                    </a>{' '}
                    <a
                      onClick={() => {
                        setCompanyApproved({
                          pending: credits?.pending_space_credit_request,
                          total: plan?.credit_balance,
                        });
                        setSelectedSpace(credits?.space?.name);
                      }}
                    >
                      Allocate Credits
                    </a>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="manage-credits-col-4">
          <div className="card">
            <div className="">
              <h4>Credits</h4>
              <div className="credit-balance">
                <span className="total-credits">Total Credits:</span>
                {plan?.total_credits}
              </div>
            </div>
            <div className="">
              <em className="thumb">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={creditsGraph(graphData, {
                    height: '90px',
                    fontSize: '11px',
                    pointWidth: 11,
                  })}
                />
              </em>
            </div>
          </div>
        </div>
      </div>

      {/* Feedbak dialog starts */}
      {connectWithExpert && (
        <FeedbackCreditDialog {...{ connectWithExpert, handleClose }} />
      )}
      {/* Feedbak dialog ends */}

      {/* Company Approved dialog starts */}
      {companyApproved && (
        <AllocateCreditDialog
          {...{ companyApproved, handleClose, selectedSpace }}
        />
      )}
      {/* Company Approved dialog ends */}
    </div>
  );
}

export default ManageCredit;
