import Tooltip from '@mui/material/Tooltip';
import { useEffect, useRef, useState } from 'react';

interface Props {
  tooltip: string;
  placement?: any;
  text?: string;
  className?: string;
  style?: any;
  html?: any;
}

const OverflowTooltip = (props: Props) => {
  const [hoverStatus, setHoverStatus] = useState(false);
  const textElementRef = useRef<HTMLInputElement | null>(null);

  const compareSize = () => {
    const compare =
      textElementRef?.current?.scrollWidth >
      textElementRef?.current?.clientWidth;
    setHoverStatus(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, []);

  useEffect(
    () => () => {
      window.removeEventListener('resize', compareSize);
    },
    []
  );

  return (
    <Tooltip
      arrow
      title={props.tooltip}
      disableHoverListener={!hoverStatus}
      classes={{ popper: 'info-tooltip bottom-arrow1' }}
      placement={props?.placement}
    >
      <span
        ref={textElementRef}
        className={props.className}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: 'inline-block',
          width: '100%',
          ...props?.style,
        }}
      >
        {props?.html}
        {props?.text}
      </span>
    </Tooltip>
  );
};

export default OverflowTooltip;
