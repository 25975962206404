import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { startTransition, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import images from '../../assets/images';
import { getCurrentSpaceDetail } from '../../shared/services/common.service';
import { currentSpaceState } from '../../shared/store/slices/companySlice';
import {
  companyState,
  permissionState,
  userPermissionState,
  userState,
} from '../../shared/store/slices/userSlice';
import {
  ON_BOARDING_WAITING_PAYMENT,
  SPACE_LIST,
} from '../../shared/util/constant';
import {
  canViewPrivateSpace,
  isAllowedTo,
  transNavigation,
} from '../../shared/util/util';
import './spaces.scss';
import RightPageHeader from '../../shared/shared-comp/RightPageHeader';

export default function SpaceTopNavigation() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = location;
  const { id } = useParams() ?? { id: null };
  const splitLocation = pathname.split('/');

  const currentSpaceSel = useSelector(currentSpaceState);
  const companySel = useSelector(companyState);
  const userSel = useSelector(userState);
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);

  const entity = { id: currentSpaceSel?.id, type: 'Space' };

  useEffect(() => {
    if (companySel?.id) {
      fetchDetails();
    }
  }, [id, companySel?.id]);

  const fetchDetails = () => {
    getCurrentSpaceDetail(dispatch, companySel?.id, id);
  };

  const isPrivateSpaceOwner = () => {
    return (
      !currentSpaceSel?.private ||
      (currentSpaceSel?.private &&
        canViewPrivateSpace(userSel, currentSpaceSel))
    );
  };

  //===========================================
  return (
    <>
      <div className="main-page-header">
        <div className="page-breacrumb">
          <div className="breadcrumb-wrapper">
            <ul className="breadcrumb-links">
              <li>
                <a
                  onClick={() =>
                    transNavigation(
                      startTransition,
                      navigate,
                      ON_BOARDING_WAITING_PAYMENT
                    )
                  }
                >
                  Building
                </a>
              </li>
              <li>
                {isAllowedTo('update', 'space', permissions) ? (
                  <Link to={SPACE_LIST}>Spaces</Link>
                ) : (
                  'Spaces'
                )}
              </li>
              <li>{isPrivateSpaceOwner() && currentSpaceSel?.name}</li>
            </ul>
          </div>
          <RightPageHeader />
        </div>

        {isPrivateSpaceOwner() && (
          <div className="page-title">
            <h2 aria-label="member-heading">{currentSpaceSel?.name}</h2>
            {currentSpaceSel?.description ? (
              <div className="page-filters-wrapper align-items-start">
                <div className="left-filter">
                  <span aria-label="count" className="count">
                    {currentSpaceSel?.description}
                  </span>
                </div>
              </div>
            ) : (
              <p>
                Creating stellar apps for our warehouses, distribution centres
                and retailers.
              </p>
            )}
          </div>
        )}
      </div>

      <div className="projects-top-nav-wrapper">
        <div className="page-top-navigation">
          <ul>
            <li>
              <a
                onClick={() =>
                  transNavigation(
                    startTransition,
                    navigate,
                    `/company/spaces/${currentSpaceSel?.id}/overview`
                  )
                }
                className={
                  splitLocation[splitLocation?.length - 1] === 'overview'
                    ? 'active'
                    : ''
                }
              >
                Overview
              </a>
            </li>
            {isAllowedTo(
              'read',
              'space_goal',
              permissions,
              entity,
              userPermissions
            ) && (
              <li>
                <a
                  onClick={() =>
                    transNavigation(
                      startTransition,
                      navigate,
                      `/company/spaces/${currentSpaceSel?.id}/space-goals`
                    )
                  }
                  className={
                    splitLocation[splitLocation?.length - 1] === 'space-goals'
                      ? 'active'
                      : ''
                  }
                >
                  Goals
                </a>
              </li>
            )}
            {isAllowedTo(
              'read',
              'space_member',
              permissions,
              entity,
              userPermissions
            ) && (
              <li>
                <a
                  onClick={() =>
                    transNavigation(
                      startTransition,
                      navigate,
                      `/company/spaces/${currentSpaceSel?.id}/team`
                    )
                  }
                  className={
                    splitLocation[splitLocation?.length - 1] === 'team'
                      ? 'active'
                      : ''
                  }
                >
                  Members
                </a>
              </li>
            )}
            {isAllowedTo(
              'read',
              'space_credit',
              permissions,
              entity,
              userPermissions
            ) && (
              <li>
                <a
                  onClick={() =>
                    transNavigation(
                      startTransition,
                      navigate,
                      `/company/spaces/${currentSpaceSel?.id}/credits`
                    )
                  }
                  className={
                    splitLocation[splitLocation?.length - 1] === 'credits'
                      ? 'active'
                      : ''
                  }
                >
                  Credits
                </a>
              </li>
            )}
            <li>
              <a
                onClick={() =>
                  transNavigation(
                    startTransition,
                    navigate,
                    `/company/spaces/${currentSpaceSel?.id}/activity`
                  )
                }
                className={
                  splitLocation[splitLocation?.length - 1] === 'activity'
                    ? 'active'
                    : ''
                }
              >
                Activity
              </a>
            </li>
            <li>
              <a
                onClick={() =>
                  transNavigation(
                    startTransition,
                    navigate,
                    `/company/spaces/${currentSpaceSel?.id}/meetings`
                  )
                }
                className={
                  splitLocation[splitLocation?.length - 1] === 'meetings'
                    ? 'active'
                    : ''
                }
              >
                Meetings
              </a>
            </li>
          </ul>
        </div>
        <div className="page-filters-wrapper m-0">
          <div className="right-filter full-width justify-right">
            {isAllowedTo(
              'update',
              'buildcard',
              permissions,
              entity,
              userPermissions
            ) && (
              <Button
                variant="contained"
                className="sm-btn purple-btn btn-start-working-idea"
                onClick={() =>
                  navigate(`/company/spaces/${id}/space-playground`)
                }
              >
                Start working on an idea
              </Button>
            )}
            <Tooltip
              arrow
              placement="top"
              title="Ideaboard"
              classes={{ popper: 'info-tooltip' }}
            >
              <Button
                variant="outlined"
                className="xs-btn is-icon-btn icon btn-idea-board"
                onClick={() => navigate(`/company/spaces/${id}/ideaboard`)}
              >
                <img src={images.iconIdeaBoard} alt="Ideaboard" />
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
}
