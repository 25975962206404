import instance from '../../../shared/interceptor';
import { bmeetUrl } from '../../../shared/store/slices/userSlice';
import { COMPANY, USER_PROFILE } from '../../../shared/util/constant';

export const getUserProfileUrl = (compSel, userData) => {
  return `${USER_PROFILE.replace(':id', compSel?.id).replace(':user_id', userData?.id)}/dashboards`;
};

export const getBuildcards = (compSel, userData) => {
  const url = `${getUserProfileUrl(compSel, userData)}/user_build_cards`;
  return instance.get(url);
};

export const getMeetings = (compSel, userData) => {
  const url = `${getUserProfileUrl(compSel, userData)}/user_meetings`;
  return instance.get(url);
};

export const getRecentReleases = (compSel, userData) => {
  const url = `${getUserProfileUrl(compSel, userData)}/recent_releases`;
  return instance.get(url);
};

export const getUpcomingReleases = (compSel, userData) => {
  const url = `${getUserProfileUrl(compSel, userData)}/upcoming_releases`;
  return instance.get(url);
};

export const getTodoAndReleases = (compSel, userData) => {
  const url = `${getUserProfileUrl(compSel, userData)}/overdue_todos_and_releases`;
  return instance.get(url);
};

export const getUserSpaceList = (compSel, userData) => {
  const url = `${getUserProfileUrl(compSel, userData)}/user_spaces`;
  return instance.get(url);
};

export const getUserSpaceGoals = (compSel, userData) => {
  const url = `${getUserProfileUrl(compSel, userData)}/user_space_goals`;
  return instance.get(url);
};

export const joinMeeting = (meet, dispatch, navigate) => {
  if (meet?.external) {
    window.open(meet?.url, '__blank');
  } else {
    dispatch(bmeetUrl(meet));
    navigate(
      `/company/spaces/${meet?.space_id}/meeting-playground/${meet?.id}`
    );
  }
};

export const redirectRelease = (card, navigate) => {
  if (card?.url?.includes(process.env.REACT_APP_DOMAIN)) {
    navigate(card?.url?.split(process.env.REACT_APP_DOMAIN)[1]);
  } else {
    window.open(card?.url, '_self');
  }
};

export const getAllCompanyGoals = (compSel) => {
  const url = `${COMPANY}/${compSel?.id}/company_goals`;
  return instance.get(url);
};
