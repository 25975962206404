import instance from '../interceptor';
import { currentSpace } from '../store/slices/companySlice';
import { currentGoalDetail } from '../store/slices/goalSlice';
import { setSpaceCreditData } from '../store/slices/spaceCreditSlice';
import { setSuccessMsg } from '../store/slices/successSlice';
import {
  COMPANY,
  ENT_USERS,
  PROJECTS,
  SPACES,
  USER_SEARCH,
} from '../util/constant';
import { globalError, setLoader } from '../util/util';

export const getCurrentSpaceDetail = async (
  dispatch,
  compId,
  paramId,
  navigate = null,
  route = null,
  transition = null
) => {
  try {
    const url = `${SPACES.replace(':id', compId)}/${paramId}`;
    const spaceRes = await instance.get(url);
    dispatch(currentSpace(spaceRes['space']));
    if (navigate) {
      transition(() => {
        navigate(route);
      });
    }
  } catch (e) {
    globalError(dispatch, e);
  }
};

export const getAllTags = async (compSel, dispatch, query?) => {
  try {
    let url = `${COMPANY}/${compSel?.id}/tags`;
    if (query) {
      url += `?q=${encodeURIComponent(query?.toLowerCase())}`;
    }
    return await instance.get(url);
  } catch (e) {
    globalError(dispatch, e);
  }
};

export const addTag = async (compSel, dispatch, tagName, card) => {
  try {
    tagName = tagName?.trim()?.toLowerCase();
    if (!tagName) return;
    let url = `${COMPANY}/${compSel?.id}/entity_tags`;
    let payload = {
      entity_tag: {
        name: encodeURIComponent(tagName),
        taggable_id: card?.enterprise_id ?? card?.id,
        taggable_type: 'BuildCard',
      },
    };
    let res = await instance.post(url, payload);
    dispatch(setSuccessMsg('Tag added successfully.'));
    return res;
  } catch (e) {
    globalError(dispatch, e);
  }
};

export const handleDelete = async (dispatch, compSel, tagId) => {
  try {
    const url = `${COMPANY}/${compSel?.id}/entity_tags/${tagId}`;
    let res = await instance.delete(url);
    dispatch(setSuccessMsg('Tag deleted successfully.'));
    return res;
  } catch (err) {
    globalError(dispatch, err);
  }
};

export const getSpaceMemberList = async (
  companySel,
  currentSpace,
  params,
  signal,
  dispatch
) => {
  try {
    if (companySel?.id && (params?.id || currentSpace?.id)) {
      let url = `${SPACES.replace(':id', companySel?.id)}/${params?.id ?? currentSpace?.id}/all_members`;
      return await instance.get(url, { signal });
    }
  } catch (e) {
    globalError(dispatch, e);
  }
};

export const getConfirmedUserList = async (
  companySel,
  searchText,
  dispatch,
  setLoading
) => {
  try {
    setLoading(true);
    let url = `${ENT_USERS.replace(':id', companySel?.id)}?confirmed=true`;
    if (searchText) {
      url += `&q=${searchText}`;
    }
    return await instance.get(url);
  } catch (e) {
    globalError(dispatch, e);
  } finally {
    setLoading(false);
  }
};

export const getUserListWithoutPermission = async (
  companySel,
  searchText,
  dispatch,
  setLoading,
  confirmed = false
) => {
  try {
    setLoading(true);
    let url = `${USER_SEARCH.replace(':id', companySel?.id)}`;
    if (confirmed) {
      url += `?confirmed=true`;
    }
    if (searchText) {
      url += `${confirmed ? '&' : '?'}q=${searchText}`;
    }
    return await instance.get(url);
  } catch (e) {
    return;
    globalError(dispatch, e);
  } finally {
    setLoading(false);
  }
};

export const getAllSpaceGoals = async (dispatch, compSel, params) => {
  try {
    const url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/space_goals`;
    let res = await instance.get(url);
    return res;
  } catch (e) {
    globalError(dispatch, e);
  }
};

const getSpaceCreditDetail = async (compSel, currentSpaceSel) => {
  const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/space_credits/${currentSpaceSel?.active_space_credit_id}`;
  return instance.get(url);
};

const getBuildcardConsumption = async (compSel, currentSpaceSel) => {
  const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/space_credits/${currentSpaceSel?.active_space_credit_id}/buildcard_consumption_data`;
  return instance.get(url);
};

const getDraftBuildcardEstimation = async (compSel, currentSpaceSel) => {
  const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/space_credits/${currentSpaceSel?.active_space_credit_id}/draft_build_cards_estimation`;
  return instance.get(url);
};

export const getSpaceCreditData = async (
  compSel,
  currentSpaceSel,
  dispatch
) => {
  try {
    let res = await Promise.all([
      getSpaceCreditDetail(compSel, currentSpaceSel),
      getBuildcardConsumption(compSel, currentSpaceSel),
      getDraftBuildcardEstimation(compSel, currentSpaceSel),
    ]);
    dispatch(setSpaceCreditData(res));
    return res;
  } catch (err) {
    globalError(dispatch, err);
  }
};

export const getGoalDetailCom = async (params, compSel, dispatch) => {
  try {
    const goalId = params?.goal_id;
    const url = `${COMPANY}/${compSel?.id}/company_goals/${goalId}`;
    let res = await instance.get(url);
    dispatch(currentGoalDetail(res?.['company_goal']));
    return res?.['company_goal'];
  } catch (e) {
    globalError(dispatch, e);
  }
};

export const createExternalAppSession = (
  params,
  compSel,
  app_name,
  dispatch
) => {
  try {
    const url = `${PROJECTS.replace(':id', compSel?.id).replace(':space_id', params?.id)}/external_app_sessions`;
    const res = instance.post(url, { session: { app_name } });
  } catch (e) {
    globalError(dispatch, e);
  }
};

export const clearBuildCardCache = async (
  buildCardId,
  spaceId,
  companyId,
  dispatch
) => {
  try {
    const url = `${PROJECTS.replace(':id', companyId).replace(':space_id', spaceId)}/build_cards/${buildCardId}/clear_cache`;
    instance.patch(url);
  } catch (e) {
    globalError(dispatch, e);
  }
};
